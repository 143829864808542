import { Currency } from "@redotech/money/currencies";
import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { ProductZod } from "@redotech/redo-model/return";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { ItemDetail } from "../../shared-components/item-detail";
import { useReturnItems } from "../hooks/use-return-items";
//Todo support multiple shipments
export function ReturnItems({
  products,
  currency,
}: {
  products: ProductZod[] | undefined;
  currency: Currency;
}) {
  const screen = useScreenType();
  const { items, texts } = useReturnItems({ products, currency });
  return (
    <Flex flexDirection="column" gap="xl">
      <Text fontSize="lg" fontWeight="semibold">
        {texts.returnItems}
      </Text>
      {items?.map((item, i) => (
        <ItemDetail
          formattedPrice={item.formattedPrice}
          id={item.id}
          imageSrc={item.imageSrc}
          isSelected={item.isSelected}
          isVertical={screen === ScreenType.DESKTOP}
          key={item.id}
          quantity={item.quantity}
          subtitle={item.subtitle}
          title={item.title}
          variants={item.variants}
        />
      ))}
    </Flex>
  );
}

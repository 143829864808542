import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
export function DisplayHtml({ html }: { html: string }) {
  const isHtml = html.includes("<") && html.includes(">");
  return (
    <Flex gap="md" height="half-screen" w="full">
      {isHtml && (
        <Flex height="full" w="full">
          <div
            // TODO parse HTML safely
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Flex>
      )}
      {!isHtml && <Text textAlign="center">{html}</Text>}
    </Flex>
  );
}

import { z } from "zod";
export enum Status {
  NOT_STARTED = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum ReturnCreationActivity {
  INCREMENT_RETURN_SUBMITTED_ACTIVITY = "incrementReturnSubmittedActivity",
  TRIGGER_CUSTOMER_EVENT_ACTIVITY = "triggerCustomerEventActivity",
  UPDATE_ORDER_WITH_EXCHANGE_ITEMS_ACTIVITY = "updateOrderWithExchangeItemsActivity",
  POPULATE_TIMELINE_ACTIVITY = "populateTimelineActivity",
  CREATE_EXCHANGE_ACTIVITY = "createExchangeActivity",
  CREATE_REPAIR_INVOICE_ACTIVITY = "createRepairInvoiceActivity",
  CREATE_LABEL_ACTIVITY = "createLabelActivity",
  REFUND_LABEL_ACTIVITY = "refundLabelActivity",
  EMAIL_IN_STORE_RETURN_ACTIVITY = "emailInStoreReturnActivity",
  CREATE_SHOPIFY_RETURN_ACTIVITY = "createShopifyReturnActivity",
  UPDATE_SHOP_APP_METAFIELDS_ACTIVITY = "updateShopAppMetafieldsActivity",
  REPORT_CLAIM_TO_PROVIDER_ACTIVITY = "reportClaimToProviderActivity",
  NOTIFY_AND_TRIGGER_STATUS_CHANGE_ACTIVITY = "notifyAndTriggerStatusChangeActivity",
}

export const ActivityStatusSchema = z.object({
  status: z.nativeEnum(Status),
  error: z.string().nullish(),
  name: z.nativeEnum(ReturnCreationActivity),
});

export type ActivityStatus = z.infer<typeof ActivityStatusSchema>;

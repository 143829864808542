import { Currency } from "@redotech/money/currencies";
import { Address } from "@redotech/redo-model/draft-return/draft-return";
import { ReturnableItem } from "@redotech/redo-model/draft-return/draft-return-items";
import { getCountryName } from "@redotech/redo-model/draft-return/util";
import { ReturnAddress } from "@redotech/redo-model/return";
import { ProductInfo } from "@redotech/shopify-storefront/products";
import { ProductData } from "./hooks/useFlow/use-flow";
import { ReturnAppSettings } from "./settings";
export const isInternationalReturn = (
  shipFromCountry: string | undefined,
  shipToCountry: string | undefined,
  settings: ReturnAppSettings,
): boolean => {
  let shipFromCountryName: string | undefined = shipFromCountry;
  if (shipFromCountry?.length == 2) {
    shipFromCountryName = getCountryName(shipFromCountry);
  }
  const shipToCountryName =
    shipToCountry || settings.merchantAddress?.country_name;

  return shipFromCountryName !== shipToCountryName;
};

export function ReturnAddressToAddress(address: ReturnAddress): Address {
  return {
    name: address.name ?? "",
    email: address.email ?? "",
    street1: address.address1,
    street2: address.address2,
    state: address.province,
    city: address.city,
    country: address.country ?? address.country_code ?? "",
    zip: address.zip ?? "",
    phone: address.phone,
    returnerName: address.name,
    returnerEmail: address.email,
  };
}

export function removeGidFromId(id: string) {
  const lastSlash = id.lastIndexOf("/");
  if (lastSlash === -1) return id;
  return id.slice(lastSlash + 1);
}

export const CURRENCY_FORMAT = (
  currencyCode: Currency,
  signDisplay?: keyof Intl.NumberFormatOptionsSignDisplayRegistry,
) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
    signDisplay: signDisplay,
  });
};

const continueIfOutOfStock = (
  variant: { availableForSale: boolean; quantityAvailable: number },
  settings: ReturnAppSettings | undefined,
) => {
  if (!variant || !settings) {
    return false;
  }
  return (
    settings.inventory.followShopifyInventoryPolicy && variant.availableForSale
  );
};

export const isVariantInStock = (
  variant: { availableForSale: boolean; quantityAvailable: number },
  settings: ReturnAppSettings | undefined,
) => {
  if (!variant || !settings) {
    return false;
  }
  return (
    continueIfOutOfStock(variant, settings) ||
    (variant.quantityAvailable || 0) >= settings.inventory.minimum_stock
  );
};

export const storefrontProductToProductData = (
  product: ProductInfo,
  settings: ReturnAppSettings | undefined,
): ProductData => {
  return {
    productId: product.id,
    productTitle: product.title,
    options: product.options.map((option) => ({
      name: option.name,
      values: option.values,
    })),
    variants: product.variants.nodes.map((variant) => ({
      variantId: variant.id,
      variantTitle: variant.title,
      price: {
        amount: variant.price.amount,
        currency: variant.price.currencyCode,
      },
      compareAtPrice: {
        amount: variant.price?.amount,
        currency: variant.price?.currencyCode,
      },
      image: variant.image ?? product.featuredImage ?? { url: "", altText: "" },
      options: variant.selectedOptions,
      onlineInventoryAvailable: variant.quantityAvailable ?? 0,
      isInStock: isVariantInStock(
        {
          availableForSale: variant.availableForSale,
          quantityAvailable: variant.quantityAvailable ?? 0,
        },
        settings,
      ),
    })),
  };
};
export enum ItemDetailSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export interface Variant {
  name: string;
  value: string;
}

export interface ReturnItemParams {
  id: string;
  imageSrc: string;
  title: string;
  quantity: number;
  variants: Variant[];
  formattedPrice: string;
  isSelected: boolean;
  badgeText?: string;
  size?: ItemDetailSize;
}

export function returnItemToParams(
  item: ReturnableItem,
  showBadge: boolean = false,
): ReturnItemParams | undefined {
  const primaryProduct = item.products.find(
    (product) => product.id === item.primaryProductId,
  );
  if (!primaryProduct) {
    return undefined;
  }

  const selectedOptions =
    primaryProduct.variantData.variants
      .find((variant) => variant.id == primaryProduct.variantId)
      ?.selectedOptions.filter((v) => v.name !== "Title") || [];
  return {
    id: item.id,
    imageSrc: primaryProduct.image,
    title: primaryProduct.title,
    quantity: primaryProduct.quantity ?? 1,
    variants: selectedOptions,
    formattedPrice: CURRENCY_FORMAT(
      Currency[primaryProduct.currency as keyof typeof Currency],
    ).format(+primaryProduct.price),
    isSelected: item.selected ?? false,
    badgeText: showBadge ? "Ready for return" : undefined,
  };
}

import { Currency } from "@redotech/money/currencies";
import {
  CURRENCY_FORMAT,
  ReturnItemParams,
} from "@redotech/redo-customer-portal-shared/util";
import { ProductZod } from "@redotech/redo-model/return";

type ReturnItemParamsWithSubtitle = ReturnItemParams & {
  subtitle: string | undefined;
};
interface UseReturnItemsParams {
  products: ProductZod[] | undefined;
  currency: Currency;
}

interface UseReturnItemsResponse {
  items: ReturnItemParamsWithSubtitle[];
  texts: { returnItems: string };
}

export function useReturnItems({
  products,
  currency,
}: UseReturnItemsParams): UseReturnItemsResponse {
  function productToItem(product: ProductZod): ReturnItemParamsWithSubtitle {
    const subtitle =
      product.variant_title != "Default Title"
        ? product.variant_title
        : undefined;

    return {
      id: product._id,
      imageSrc: product.images[0] ?? "",
      title: product.product_title,
      subtitle: subtitle,
      quantity: product.quantity,
      variants: [],
      formattedPrice: CURRENCY_FORMAT(currency).format(+product.price),
      isSelected: false,
    };
  }
  return {
    items: products?.map(productToItem) || [],
    texts: { returnItems: "Return items" },
  };
}

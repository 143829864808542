import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { SetAuthContext } from "@redotech/redo-customer-portal-app/contexts/auth";
import { CustomerContext } from "@redotech/redo-customer-portal-app/contexts/CustomerContext";
import { SettingsContext } from "@redotech/redo-customer-portal-app/contexts/settings";
import {
  SetUserContext,
  UserContext,
} from "@redotech/redo-customer-portal-app/contexts/user";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { Avatar } from "@redotech/redo-web/arbiter-components/avatars/avatar";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import LeftArrow from "@redotech/redo-web/arbiter-icon/arrow-left.svg";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { getGravatarUrlFromEmail } from "@redotech/redo-web/gravatar-utils";
import { Text } from "@redotech/redo-web/text";
import { allowCustomerAccounts } from "@redotech/redo-web/utils/customer-account-utils";
import { assertNever } from "@redotech/util/type";
import { memo, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as navbarCss from "./version-four-navbar.module.css";
enum MenuOption {
  MyOrders = "My orders",
  MyAccount = "My account",
  Logout = "Logout",
}

export function VersionFourNavbar() {
  const settings = useContext(SettingsContext);
  const customerContext = useContext(CustomerContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const user = useContext(UserContext);
  const setUser = useContext(SetUserContext);
  const setAuth_ = useContext(SetAuthContext);
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const screen = useScreenType();

  const primaryEmail = getPrimaryCustomerEmail(customerContext);
  const customerName = customerContext?.name;
  const myOrdersOption = !user.guestEmail ? [MenuOption.MyOrders] : [];
  const myAccountOption =
    allowCustomerAccounts(settings.customerAccounts) &&
    !!customerContext &&
    primaryEmail
      ? [MenuOption.MyAccount]
      : [];

  const menuOptions = [
    myOrdersOption,
    myAccountOption,
    MenuOption.Logout,
  ].flat();

  const optionToLabel: Record<MenuOption, string> = {
    [MenuOption.MyOrders]: "My Orders",
    [MenuOption.MyAccount]: "My Account",
    [MenuOption.Logout]: "Logout",
  };

  const shopAppLink = params.get("shopAppLink");
  const fromShopMini = shopAppLink !== null;

  const ShopAppBack = memo(function ShopAppBack({ link }: { link: string }) {
    return (
      <Flex
        align="center"
        flexDirection="row"
        justify="center"
        onClick={() => window.open(link)}
      >
        <LeftArrow />
        Shop App
      </Flex>
    );
  });

  return (
    <Flex
      align="center"
      bgColor={screen === ScreenType.DESKTOP ? "primary" : "secondary"}
      borderBottomWidth="1px"
      borderColor="secondary"
      borderStyle="solid"
      className={navbarCss.navBar}
      dir="row"
      justify="space-between"
      px="sm"
      py="lg"
    >
      {fromShopMini ? (
        <ShopAppBack link={shopAppLink} />
      ) : (
        <a
          href={`https://${settings.storeUrl}`}
          rel="noreferrer"
          target="_blank"
        >
          <img
            alt="Store logo"
            className={navbarCss.navBarLogo}
            id="navbar-logo"
            src={settings.theme?.logo_image}
          />
        </a>
      )}

      <RedoSingleSelectDropdown
        dropdownButtonRef={dropdownButtonRef}
        options={menuOptions.map((option) => ({
          value: option,
          label: optionToLabel[option],
        }))}
        optionSelected={(item: RedoListItem<MenuOption>) => {
          switch (item.value) {
            case MenuOption.MyOrders:
              navigate("/returns-portal/orders");
              break;
            case MenuOption.MyAccount:
              navigate("/returns-portal/account");
              break;
            case MenuOption.Logout:
              setAuth_(undefined);
              setUser(undefined);
              navigate("/returns-portal/login");
              break;
            default:
              assertNever(item.value);
          }
        }}
        selectedItem={undefined}
        size={RedoListItemSize.SMALL}
      >
        {(option) => {
          return <Text fontSize="xs">{option.label}</Text>;
        }}
      </RedoSingleSelectDropdown>

      <Flex align="center" gap="sm">
        <Flex align="center" dir="row">
          {screen === ScreenType.DESKTOP && (primaryEmail || customerName) && (
            <Flex align="flex-end" dir="column" gap="none">
              {customerName && (
                <Text fontSize="sm" fontWeight="bold">
                  {customerName}
                </Text>
              )}
              {primaryEmail && (
                <Text fontSize="sm" fontWeight="thin">
                  {primaryEmail}
                </Text>
              )}
            </Flex>
          )}
          <Avatar
            alt={primaryEmail}
            imageUrl={
              primaryEmail ? getGravatarUrlFromEmail(primaryEmail) : null
            }
          />
        </Flex>
        <RedoButton
          hierarchy={RedoButtonHierarchy.TERTIARY}
          IconTrailing={ChevronDownIcon}
          onClick={(event) => event.stopPropagation()}
          ref={setDropdownButtonRef}
          size={RedoButtonSize.REGULAR}
        />
      </Flex>
    </Flex>
  );
}

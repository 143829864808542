import { z } from "zod";

// email or phone number
export const submitSignupFormStepSchema = {
  input: z.object({
    formId: z.string(),
    stepIndex: z.number(),
    formData: z.object({
      email: z.string().optional(),
      phone: z.string().optional(),
    }),
    customerId: z.string().nullable(),
    entryPoint: z.string(),
    anonymousId: z.string().optional(),
  }),
  output: z.object({
    success: z.boolean(),
    error: z.string().optional(),
    customerId: z.string().nullable(),
  }),
};

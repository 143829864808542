import { TopStatus } from "@redotech/redo-customer-portal-shared/hooks/useReturnConfirmation/use-return-confirmation";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  FeaturedIconColor,
  FeaturedIconSize,
  RedoFeaturedIcon,
} from "@redotech/redo-web/arbiter-components/featured-icon/redo-featured-icon";
import Check from "@redotech/redo-web/arbiter-icon/check.svg";
import ClockRefresh from "@redotech/redo-web/arbiter-icon/clock-refresh_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
export function TopCard({
  status,
  title,
  subtitle,
  action,
  actionTitle,
}: {
  status: TopStatus;
  title: string;
  subtitle: string;
  action?: () => void;
  actionTitle?: string;
}) {
  function getStatusIcon(status: TopStatus) {
    switch (status) {
      case TopStatus.SUCCESS:
        return (
          <RedoFeaturedIcon
            color={FeaturedIconColor.SUCCESS}
            icon={<Check />}
            size={FeaturedIconSize.MEDIUM}
          />
        );
      case TopStatus.WARNING:
        return (
          <RedoFeaturedIcon
            color={FeaturedIconColor.WARNING}
            icon={<ClockRefresh />}
            size={FeaturedIconSize.MEDIUM}
          />
        );
      case TopStatus.ERROR:
        return (
          <RedoFeaturedIcon
            color={FeaturedIconColor.ERROR}
            icon={<ClockRefresh />}
            size={FeaturedIconSize.MEDIUM}
          />
        );
      default:
        assertNever(status);
    }
  }
  return (
    <Flex flexDirection="column" gap="lg">
      {getStatusIcon(status)}
      <Flex flexDirection="column" gap="xxs">
        <Text fontSize="lg" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="sm" fontWeight="regular">
          {subtitle}
        </Text>
      </Flex>
      {action && actionTitle && (
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={action}
          text={actionTitle}
        />
      )}
    </Flex>
  );
}

import { Currency } from "@redotech/money/currencies";
import { Subtotal } from "@redotech/redo-customer-portal-shared/types";
import { CURRENCY_FORMAT } from "@redotech/redo-customer-portal-shared/util";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { IndentedTotals } from "./review/indented-totals";

export const SummaryCard = memo(function SummaryCard({
  labelDeducted,
  shippingFee,
  totalShippingFee,
  pickupFee,
  newOrderValue,
  currency,
  paymentOwed,
  totalRefund,
  totalStoreCredit,
  feesHeader,
  feesOrderHeader,
  adjustmentValue,
  adjustmentValueString,
  returnValue,
  newOrderTaxes,
  newVariantExchangeItemSubtotals,
  newOrderItemsSubtotals,
  productSubtotals,
  taxSubtotals,
}: {
  feesHeader: string;
  feesOrderHeader: string;
  labelDeducted: boolean;
  shippingFee: number;
  totalShippingFee: number;
  pickupFee?: number;
  newOrderValue: number;
  currency: Currency;
  paymentOwed: number;
  totalRefund: number;
  totalStoreCredit: number;
  adjustmentValue?: number;
  adjustmentValueString?: string;
  returnValue: number;
  newOrderTaxes: Subtotal[];
  newVariantExchangeItemSubtotals: Subtotal[];
  newOrderItemsSubtotals: Subtotal[];
  productSubtotals: Subtotal[];
  taxSubtotals: Subtotal[];
}) {
  const currencyFormatter = CURRENCY_FORMAT(currency);

  return (
    <Flex flexDirection="column" gap="xl">
      <Text fontSize="lg" fontWeight="semibold">
        Summary
      </Text>
      <IndentedTotals
        currencyFormatter={currencyFormatter}
        subtotals={[...productSubtotals, ...taxSubtotals]}
        title={feesHeader}
        total={returnValue}
      />
      {adjustmentValue && adjustmentValueString && adjustmentValue !== 0 ? (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm">{adjustmentValueString}</Text>
          <Text fontSize="sm" textColor="secondary">
            {currencyFormatter.format(adjustmentValue)}
          </Text>
        </Flex>
      ) : null}
      {newOrderValue > 0 && (
        <IndentedTotals
          currencyFormatter={currencyFormatter}
          subtotals={[
            ...newOrderItemsSubtotals,
            ...newVariantExchangeItemSubtotals,
            ...newOrderTaxes,
          ]}
          title={feesOrderHeader}
          total={newOrderValue}
        />
      )}
      {(labelDeducted || totalShippingFee > 0) && (
        <>
          <Flex dir="row" justify="space-between">
            <Text fontSize="sm" textColor="secondary">
              Return shipping
            </Text>
            <Text fontSize="sm" textColor="secondary">
              {currencyFormatter.format(shippingFee)}
            </Text>
          </Flex>
          {pickupFee && (
            <Flex dir="row" justify="space-between">
              <Text fontSize="sm" textColor="secondary">
                Package pickup
              </Text>
              <Text fontSize="sm" textColor="secondary">
                {currencyFormatter.format(pickupFee)}
              </Text>
            </Flex>
          )}
        </>
      )}
      <Divider />
      {(paymentOwed > 0 || totalShippingFee > 0) && (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm">Payment owed</Text>
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {currencyFormatter.format(paymentOwed + totalShippingFee)}
          </Text>
        </Flex>
      )}
      {totalStoreCredit > 0 && (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm" textColor="secondary">
            Store credit
          </Text>
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {currencyFormatter.format(totalStoreCredit)}
          </Text>
        </Flex>
      )}
      {totalRefund > 0 && (
        <Flex dir="row" justify="space-between">
          <Text fontSize="sm" textColor="secondary">
            Refund owed
          </Text>
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {currencyFormatter.format(totalRefund)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
});

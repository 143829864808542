import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ArrowLeft from "@redotech/redo-web/arbiter-icon/arrow-left.svg";
import { Flex } from "@redotech/redo-web/flex";
export function NavigationHeader({
  previousClicked,
  headerRight,
}: {
  previousClicked: () => void;
  headerRight: React.ReactNode;
}) {
  return (
    <Flex justify="space-between" p="none">
      <RedoButton
        centerItems={false}
        hierarchy={RedoButtonHierarchy.LINK}
        IconLeading={ArrowLeft}
        onClick={previousClicked}
        size={RedoButtonSize.LARGE}
      />
      {headerRight}
    </Flex>
  );
}

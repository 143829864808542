// extracted by mini-css-extract-plugin
export var dark = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-dark";
export var iconHighlight = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-icon-highlight";
export var lg = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-lg";
export var light = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-light";
export var md = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-md";
export var modern = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-modern";
export var sm = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-sm";
export var xl = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-xl";
export var xs = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-xs";
export var xxs = "_redotech-redo-web-src-arbiter-components-featured-icon-redo-featured-icon-xxs";
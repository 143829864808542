import {
  Address,
  CompensationMethodType,
  DraftReturn,
  ReturnTotalCalculationsOutput,
} from "@redotech/redo-model/draft-return/draft-return";
import { ReturnableItem } from "@redotech/redo-model/draft-return/draft-return-items";
import { Channel, ReturnTypeEnum } from "@redotech/redo-model/return";
import { ProvisionType } from "@redotech/redo-model/return-flow";
import { ExchangesSelection } from "@redotech/redo-model/team";
import { assertNever } from "@redotech/util/type";
import { ItemReturnFlowStep } from "./flow/util";
import { ProductData } from "./hooks/useFlow/use-flow";
import { ReturnAppSettings } from "./settings";

import { datadogLogs } from "@datadog/browser-logs";
import { ProductInfo } from "@redotech/shopify-storefront";
export class NavigatorWithLogger implements Navigator {
  constructor(private navigator: Navigator) {}

  push<T extends RouteName>(
    ...args: undefined extends StackParamList[T]
      ? [screen: T] | [screen: T, params: StackParamList[T]]
      : [screen: T, params: StackParamList[T]]
  ): void {
    datadogLogs.logger.info("screen_list.push", {
      screen: args[0],
      params: args[1],
    });
    this.navigator.push(...args);
  }
  replace<T extends RouteName>(
    ...args: undefined extends StackParamList[T]
      ? [screen: T] | [screen: T, params: StackParamList[T]]
      : [screen: T, params: StackParamList[T]]
  ): void {
    datadogLogs.logger.info("screen_list.replace", {
      screen: args[0],
      params: args[1],
    });
    this.navigator.replace(...args);
  }
  pop(count?: number): void {
    this.navigator.pop(count);
  }
  dispatch(action: {
    type: "RESET";
    payload: {
      index: number;
      routes: Omit<StackParamListType<RouteName>, "isModal">[];
    };
  }): void {
    const lastScreen = action.payload.routes[action.payload.routes.length - 1];
    datadogLogs.logger.info("screen_list.dispatch", {
      screen: lastScreen?.name,
      params: lastScreen?.params,
    });
    this.navigator.dispatch(action);
  }
  setOptions(options: any): void {
    //No logging necessary
    this.navigator.setOptions(options);
  }
  currentScreen(): StackParamListType<RouteName> | undefined {
    if (!this.navigator.currentScreen) {
      throw new Error("currentScreen not implemented");
    }
    return this.navigator.currentScreen();
  }
  getStack(): StackParamListType<RouteName>[] {
    if (!this.navigator.getStack) {
      throw new Error("getStack not implemented");
    }

    return this.navigator.getStack();
  }
  navigate(options: Omit<StackParamListType<RouteName>, "isModal">): void {
    datadogLogs.logger.info("screen_list.navigate", {
      screen: options.name,
      params: options.params,
    });
    this.navigator.navigate(options);
  }
}
export interface Navigator {
  /**
   * Navigate to a screen, will search for the screen in the stack and navigate back if it exists
   */
  navigate(options: Omit<StackParamListType<RouteName>, "isModal">): void;

  /**
   * Push a screen to the stack, can push multiple screens on top
   */
  push<T extends RouteName>(
    ...args: undefined extends StackParamList[T]
      ? [screen: T] | [screen: T, params: StackParamList[T]]
      : [screen: T, params: StackParamList[T]]
  ): void;

  /**
   * Replace the current screen with a new screen
   */
  replace<T extends RouteName>(
    ...args: undefined extends StackParamList[T]
      ? [screen: T] | [screen: T, params: StackParamList[T]]
      : [screen: T, params: StackParamList[T]]
  ): void;

  /**
   * Pop the current screen from the stack
   */
  pop(count?: number): void;

  dispatch(action: {
    type: "RESET";
    payload: {
      index: number;
      routes: Omit<StackParamListType<RouteName>, "isModal">[];
    };
  }): void;

  getStack?(): StackParamListType<RouteName>[];

  currentScreen?(): StackParamListType<RouteName> | undefined;

  setOptions(options: any): void;
}

export enum StepInReturn {
  SELECT = "select",
  ITEM_DETAILS = "item-details",
  CHOOSE_COMPENSATION_METHOD = "choose-compensation-method",
  SHOP_NOW = "shop-now",
  SHOP_ON_SITE = "shop-on-site",
  SHIPPING_ADDRESS = "shipping-address",
  CHOOSE_SHIPMENT_METHOD = "choose-shipment-method",
  NEW_ORDER_METHOD = "new-order-method",
  REVIEW_RETURN = "review-return",
  WEB_REVIEW_RETURN = "web-review-return",
  PACKAGE_PICKUP_UPSELL = "package-pickup-upsell",
  PACKAGE_PICKUP = "package-pickup",
}

export enum ReturnAddressType {
  SHIPPING = "Shipping",
  NEW_ORDER = "New Order",
}
export type ParamListBase = Record<string, object | undefined>;
//Enforces params to be passed for each screen if needed
export type StackParamListType<T> = T extends RouteName
  ? { name: T; params: StackParamList[T]; isModal: boolean }
  : never;

export enum RouteName {
  LOGIN = "login",
  SELECT_ITEMS = "select-items",
  ITEM_DETAILS = "item-details",
  MODAL_STACK = "modal-stack",
  FLOW = "flow",
  IMAGE = "image",
  PRODUCT_PICKER = "product-picker",
  CHOOSE_COMPENSATION_METHOD = "choose-compensation-method",
  ADDRESS = "address",
  EDIT_ADDRESS = "edit-address",
  REVIEW = "review",
  SHIPMENT_METHOD = "shipment-method",
  RETURN_CONFIRMATION = "return-confirmation",
  SHOP_NOW = "shop-now",
  SHOP_ON_SITE = "shop-on-site",
  CART = "cart",
  CONFIRM_ADDRESS = "confirm-address",
  PACKAGE_PICKUP_UPSELL = "package-pickup-upsell",
  PACKAGE_PICKUP = "package-pickup",
  NEW_ORDER_METHOD = "new-order-method",
  VIEW_RETURNS = "view-returns",
  NAVIGATE_OUT = "navigate-out",
}

export const isModal: Record<RouteName, boolean> = {
  [RouteName.LOGIN]: false,
  [RouteName.SELECT_ITEMS]: false,
  [RouteName.ITEM_DETAILS]: false,
  [RouteName.MODAL_STACK]: true,
  [RouteName.FLOW]: true,
  [RouteName.IMAGE]: true,
  [RouteName.PRODUCT_PICKER]: true,
  [RouteName.CHOOSE_COMPENSATION_METHOD]: false,
  [RouteName.ADDRESS]: false,
  [RouteName.EDIT_ADDRESS]: true,
  [RouteName.REVIEW]: false,
  [RouteName.SHIPMENT_METHOD]: false,
  [RouteName.RETURN_CONFIRMATION]: false,
  [RouteName.SHOP_NOW]: false,
  [RouteName.SHOP_ON_SITE]: false,
  [RouteName.CART]: true,
  [RouteName.CONFIRM_ADDRESS]: true,
  [RouteName.PACKAGE_PICKUP_UPSELL]: true,
  [RouteName.PACKAGE_PICKUP]: true,
  [RouteName.NEW_ORDER_METHOD]: false,
  [RouteName.VIEW_RETURNS]: false,
  [RouteName.NAVIGATE_OUT]: false,
};

export type StackParamList = {
  [RouteName.LOGIN]: { startReturn?: boolean };
  [RouteName.SELECT_ITEMS]: undefined;
  [RouteName.ITEM_DETAILS]: undefined;
  [RouteName.MODAL_STACK]: {
    itemReturnFlowSteps: readonly ItemReturnFlowStep[];
    itemId: string;
  };
  [RouteName.FLOW]: {
    itemReturnFlowSteps: readonly ItemReturnFlowStep[];
    itemId: string;
  };
  [RouteName.IMAGE]: { uri: string };
  [RouteName.PRODUCT_PICKER]: {
    product: ProductData;
    returnableItem?: ReturnableItem;
  };
  [RouteName.CHOOSE_COMPENSATION_METHOD]: undefined;
  [RouteName.ADDRESS]: undefined;
  [RouteName.EDIT_ADDRESS]: { type: ReturnAddressType };
  [RouteName.REVIEW]: undefined;
  [RouteName.SHIPMENT_METHOD]: undefined;
  [RouteName.RETURN_CONFIRMATION]: {
    returnId: string;
    openCheckout: boolean;
    showBack?: boolean | undefined;
  };
  [RouteName.SHOP_NOW]: undefined;
  [RouteName.SHOP_ON_SITE]: {
    selectedProduct?: ProductInfo;
    shouldNavigateToShopRightAway?: boolean;
  };
  [RouteName.CART]: undefined;
  [RouteName.CONFIRM_ADDRESS]: { address: Address; type: ReturnAddressType };
  [RouteName.PACKAGE_PICKUP_UPSELL]: { pickupFee: number };
  [RouteName.PACKAGE_PICKUP]: { pickupFee: number };
  [RouteName.NEW_ORDER_METHOD]: undefined;
  [RouteName.VIEW_RETURNS]: { returnIds: string[] };
  [RouteName.NAVIGATE_OUT]: { uri?: string; startAtBeginning?: boolean }; // If not defined, navigates to return portal review page
};

export function getNextScreen(
  step: StepInReturn,
  currentStep?: StepInReturn,
): StackParamListType<RouteName> {
  switch (step) {
    case StepInReturn.SELECT:
      return {
        name: RouteName.SELECT_ITEMS,
        params: undefined,
        isModal: isModal[RouteName.SELECT_ITEMS],
      };
    case StepInReturn.ITEM_DETAILS:
      return {
        name: RouteName.ITEM_DETAILS,
        params: undefined,
        isModal: isModal[RouteName.ITEM_DETAILS],
      };
    case StepInReturn.CHOOSE_COMPENSATION_METHOD:
      return {
        name: RouteName.CHOOSE_COMPENSATION_METHOD,
        params: undefined,
        isModal: isModal[RouteName.CHOOSE_COMPENSATION_METHOD],
      };
    case StepInReturn.SHOP_NOW:
      return {
        name: RouteName.SHOP_NOW,
        params: undefined,
        isModal: isModal[RouteName.SHOP_NOW],
      };
    case StepInReturn.SHOP_ON_SITE:
      return {
        name: RouteName.SHOP_ON_SITE,
        params: {
          selectedProduct: undefined,
          shouldNavigateToShopRightAway:
            currentStep === StepInReturn.CHOOSE_COMPENSATION_METHOD,
        },
        isModal: isModal[RouteName.SHOP_ON_SITE],
      };
    case StepInReturn.SHIPPING_ADDRESS:
      return {
        name: RouteName.ADDRESS,
        params: undefined,
        isModal: isModal[RouteName.ADDRESS],
      };
    case StepInReturn.CHOOSE_SHIPMENT_METHOD:
      return {
        name: RouteName.SHIPMENT_METHOD,
        params: undefined,
        isModal: isModal[RouteName.SHIPMENT_METHOD],
      };
    case StepInReturn.PACKAGE_PICKUP_UPSELL:
      return {
        name: RouteName.PACKAGE_PICKUP_UPSELL,
        params: { pickupFee: 0 },
        isModal: isModal[RouteName.PACKAGE_PICKUP_UPSELL],
      };
    case StepInReturn.PACKAGE_PICKUP:
      return {
        name: RouteName.PACKAGE_PICKUP,
        params: { pickupFee: 0 },
        isModal: isModal[RouteName.PACKAGE_PICKUP],
      };
    case StepInReturn.REVIEW_RETURN:
      return {
        name: RouteName.REVIEW,
        params: undefined,
        isModal: isModal[RouteName.REVIEW],
      };
    case StepInReturn.NEW_ORDER_METHOD:
      return {
        name: RouteName.NEW_ORDER_METHOD,
        params: undefined,
        isModal: isModal[RouteName.NEW_ORDER_METHOD],
      };
    case StepInReturn.WEB_REVIEW_RETURN:
      return {
        name: RouteName.NAVIGATE_OUT,
        params: { uri: undefined, startAtBeginning: undefined },
        isModal: isModal[RouteName.NAVIGATE_OUT],
      };
    default:
      return assertNever(step);
  }
}

export function navigateFromStack(
  steps: StepInReturn[],
  currentStep: StepInReturn | undefined,
  navigation: Navigator,
  fastForward: boolean = true, //If true, will skip to the last step in the stack
) {
  console.log("navigateFromStack", steps, currentStep, navigation, fastForward);
  if (steps.length === 0) {
    console.error("navigateFromStack: No steps to navigate to");
    throw new Error("No steps to navigate to");
  }

  if (steps.length === 1) {
    const nextScreenParams = getNextScreen(steps[0]!, currentStep);
    navigation.replace(nextScreenParams.name, nextScreenParams.params);

    return;
  }

  const secondToLastStep = steps[steps.length - 2];
  if (secondToLastStep === currentStep) {
    navigation.navigate(getNextScreen(steps.pop()!, currentStep));
    return;
  }

  if (!fastForward) {
    const currentStepIndex = steps.findIndex((step) => step === currentStep);
    if (currentStepIndex !== -1) {
      if (currentStepIndex + 1 < steps.length) {
        navigation.navigate(
          getNextScreen(steps[currentStepIndex + 1]!, currentStep),
        );
        return;
      }
    }
  }
  //If we reach here it means we need to push more than one screen to the stack
  navigation.dispatch({
    type: "RESET",
    payload: {
      index: steps.length - 1,
      routes: steps.map((step) => {
        return getNextScreen(step, currentStep);
      }),
    },
  });
}

/*
  Returns a list of steps that the user has completed, if you need just the latest step just pop the last element.
  The list is to allow rebuilding the navigation stack when deep linking.
*/
export async function getStepsInReturn(
  draftReturn: DraftReturn,
  settings: ReturnAppSettings,
  getReturnCalculations: () => Promise<{
    calculations: ReturnTotalCalculationsOutput;
  }>,
  channel: Channel,
): Promise<StepInReturn[]> {
  const selectedItems = draftReturn.returnableItems.filter(
    (item) => item.selected,
  );

  const steps = [StepInReturn.SELECT];

  if (selectedItems.length <= 0) {
    return steps;
  }

  const pendingItemsLength = draftReturn.pendingReturnItems.length;
  steps.push(StepInReturn.ITEM_DETAILS);
  if (pendingItemsLength < selectedItems.length) {
    return steps;
  }
  const availableMethods = draftReturn.compensationMethods;
  const compensationMethod = draftReturn.selectedMethod;

  // If available methods is 1 and compensation method isn't defined, something went wrong
  if (availableMethods.length === 1 && !compensationMethod) {
    throw new Error(
      "selected compensation method must be defined if there is only one available method",
    );
  }
  if (availableMethods.length > 1) {
    steps.push(StepInReturn.CHOOSE_COMPENSATION_METHOD);
  }

  if (!compensationMethod) {
    return steps;
  }
  const foundMethod = draftReturn.compensationMethods.find(
    (t) => t.type === CompensationMethodType.EXCHANGE,
  );
  if (compensationMethod === CompensationMethodType.EXCHANGE) {
    //We should only show shop now page if there exist an item that wasn't a variant exchangeitem; (including items that don't ignore price differences which are treated as advanced exchanges)
    const nonExchangeItems = Object.entries(
      foundMethod?.itemValues || [],
    ).filter((t) => t[1].isVariantExchange === false);

    const priceDifferenceVariantExchangeItems = draftReturn.pendingReturnItems
      .filter((item) => item.variantExchangeItem != null)
      .map((t) => t.id);

    const filtered = nonExchangeItems.filter(
      (t) => !priceDifferenceVariantExchangeItems.includes(t[0]),
    );

    //If filtered is greater than 0, then we know they chose shop now option
    if (filtered.length > 0) {
      switch (settings.exchanges.selection) {
        case ExchangesSelection.RETURN_APP:
          steps.push(StepInReturn.SHOP_NOW);
          break;
        case ExchangesSelection.STORE_WEBSITE:
          if (channel === Channel.SHOP_APP) {
            steps.push(StepInReturn.SHOP_NOW);
          } else {
            steps.push(StepInReturn.SHOP_ON_SITE);
          }
          break;
      }
      const advancedExchangeItemsLength =
        draftReturn.newOrderData?.items.length ||
        0 - priceDifferenceVariantExchangeItems.length;

      if (advancedExchangeItemsLength <= 0) {
        return steps;
      }
    }
  }

  const { calculations } = await getReturnCalculations();

  if (!calculations) {
    console.error(
      "At this point a compensation method should have been selected",
    );
    return steps;
  }

  const {
    hasNonGreenReturnItems,
    showNewItems,
    totalShippingFee,
    skipPayment,
    labelDeducted,
  } = calculations.processed;

  const shipmentMethod = draftReturn.shipmentMethod;

  if (
    calculations?.processed?.hasNonGreenReturnItems ||
    calculations?.processed?.showNewItems
  ) {
    steps.push(StepInReturn.SHIPPING_ADDRESS);
  }
  //The only way to tell if shipping_address has been seen is to check if they have chosen a shipment method yet
  if (!shipmentMethod && (hasNonGreenReturnItems || showNewItems)) {
    return steps;
  }
  if (hasNonGreenReturnItems || showNewItems) {
    steps.push(StepInReturn.CHOOSE_SHIPMENT_METHOD);

    if (!shipmentMethod) {
      return steps;
    }
  }

  const exchanges = (
    draftReturn.returnType === ReturnTypeEnum.RETURN
      ? settings.automation?.returnProcessing
      : settings.automation?.claimProcessing
  )?.exchanges;

  if (channel === Channel.SHOP_APP && settings.exchanges.instantExchangeOnly) {
    steps.push(StepInReturn.WEB_REVIEW_RETURN);
    return steps;
  }
  //Provision Selection (instant exchange or processed exchange)
  if (
    channel === Channel.SHOP_APP &&
    settings.exchanges.allowInstant &&
    exchanges !== "open" &&
    hasNonGreenReturnItems &&
    draftReturn.returnType !== ReturnTypeEnum.CLAIM &&
    draftReturn.returnType !== ReturnTypeEnum.WARRANTY &&
    ((draftReturn?.newOrderData?.items || [])?.length > 0 ||
      Object.values(foundMethod?.itemValues || []).some(
        (item) => item.isVariantExchange,
      ))
  ) {
    steps.push(StepInReturn.NEW_ORDER_METHOD);

    if (!draftReturn.provisionType) {
      return steps;
    }
  }

  //If there is any shipping fee (and no skip payment/ label deducted), we must push to Web Review Return
  if (
    channel === Channel.SHOP_APP &&
    (draftReturn.provisionType === ProvisionType.INSTANT ||
      (totalShippingFee > 0 && !skipPayment && !labelDeducted))
  ) {
    steps.push(StepInReturn.WEB_REVIEW_RETURN);
    return steps;
  }

  steps.push(StepInReturn.REVIEW_RETURN);
  return steps;
}

import { AddressInfo } from "@redotech/redo-customer-portal-shared/hooks/useAddress/use-address";
import { ReturnAddressType } from "@redotech/redo-customer-portal-shared/navigator";
import {
  DraftReturn,
  ReturnTotalCalculations,
} from "@redotech/redo-model/draft-return/draft-return";
import { Flex } from "@redotech/redo-web/flex";
import { memo } from "react";
import { Address, AddressOld } from "./address";

export function Addresses({
  addresses,
  pickupNotAvailable,
}: {
  addresses: AddressInfo[];
  pickupNotAvailable?: boolean;
}) {
  return (
    <Flex dir="column" gap="lg">
      {addresses.map((addressInfo) => (
        <Address
          addressInfo={addressInfo}
          key={addressInfo.type}
          pickupNotAvailable={pickupNotAvailable}
        />
      ))}
    </Flex>
  );
}

export const AddressesOld = memo(function Addresses({
  draftReturn,
  calculations,
  pickupNotAvailable,
  edit,
  direction = "column",
}: {
  draftReturn: DraftReturn;
  calculations: ReturnTotalCalculations;
  pickupNotAvailable?: boolean;
  edit?: (type: ReturnAddressType) => void;
  direction?: "column" | "row";
}) {
  const { hasNonGreenReturnItems, showNewItems } = calculations;
  return (
    <Flex flexDirection={direction} gap="sm" justify="space-between">
      {draftReturn.customer.shippingAddress &&
        (hasNonGreenReturnItems || showNewItems) && (
          <AddressOld
            address={draftReturn.customer.shippingAddress}
            edit={edit ? () => edit(ReturnAddressType.SHIPPING) : undefined}
            pickupNotAvailable={pickupNotAvailable}
            title={
              hasNonGreenReturnItems
                ? "Returning items from:"
                : "Shipping new items to:"
            }
          />
        )}
      {hasNonGreenReturnItems && showNewItems && (
        <AddressOld
          address={draftReturn.customer.newOrderAddress ?? undefined}
          edit={edit ? () => edit(ReturnAddressType.NEW_ORDER) : undefined}
          title="Shipping new items to:"
        />
      )}
    </Flex>
  );
});

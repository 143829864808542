import { Subtotal } from "@redotech/redo-customer-portal-shared/types";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down.svg";
import ChevronUpSvg from "@redotech/redo-web/arbiter-icon/chevron-up.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import * as indentedTotalsCss from "./indented-totals.module.css";

export const IndentedTotals = memo(function IndentedTotals({
  title,
  total,
  subtotals,
  currencyFormatter,
}: {
  title: string;
  total: number;
  subtotals?: Subtotal[];
  currencyFormatter: Intl.NumberFormat;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Flex
      className={indentedTotalsCss.totalsContainer}
      flexDirection="column"
      gap="2xl"
      pt="xs"
    >
      <Flex
        align="center"
        className={indentedTotalsCss.totalsHeader}
        flexDirection="row"
        justify="space-between"
      >
        <RedoButton
          hierarchy={RedoButtonHierarchy.LINK}
          IconTrailing={
            isExpanded ? () => <ChevronUpSvg /> : () => <ChevronDownSvg />
          }
          onClick={() => setIsExpanded(!isExpanded)}
          size={RedoButtonSize.REGULAR}
          text={title}
        />
        <Text fontSize="sm" textColor="secondary">
          {currencyFormatter.format(total)}
        </Text>
      </Flex>
      {isExpanded && subtotals && (
        <>
          {subtotals.map((subtotal, i) => (
            <Flex flexDirection="row" justify="space-between" key={i} pl="xl">
              <Text fontSize="sm" textColor="secondary">
                {subtotal.title}
              </Text>
              <Text fontSize="sm" textColor="secondary">
                {currencyFormatter.format(subtotal.total)}
              </Text>
            </Flex>
          ))}
        </>
      )}
    </Flex>
  );
});

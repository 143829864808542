import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { createCustomerProductList } from "./schema/accounts/create-customer-product-list";
import { getCustomerProductList } from "./schema/accounts/get-customer-product-list";
import { addReturnItem } from "./schema/add-return-item";
import {
  addProductToOrderlessDraftReturn,
  createDraftReturn,
  createOrderlessDraftReturn,
} from "./schema/create-draft-return";
import { getFulfillmentOrdersByOrder } from "./schema/get-fulfillment-orders-by-order";
import { getProductFilterById } from "./schema/get-product-filter-by-id";
import { getReturn } from "./schema/get-return";
import { getReturnCreationStatus } from "./schema/get-return-creation-status";
import { getSharedList } from "./schema/get-shared-list";
import { getSubmissionWorkflowStatus } from "./schema/get-submission-workflow-status";
import {
  getSignupForm,
  handleMarketingEmailUnsubscribe,
  logSignupFormAnalytics,
  submitSignupFormStep,
} from "./schema/marketing";
import {
  addNewItems,
  calculateReturnTotals,
  changeAddress,
  editPendingItemQuantity,
  editReturnableItem,
  getCommentSoldRecommendedProducts,
  getDraftReturn,
  getFaq,
  getLocations,
  getOrderId,
  getOrders,
  getSatisfactionResponse,
  getShippingEstimate,
  getStripePublicKey,
  getWarrantyEligibleProducts,
  getWarrantyRegistrationFlow,
  isShopAppDisabled,
  rejectReturnItems,
  removeNewItems,
  removeReturnItem,
  saveSatisfactionResponse,
  selectShipmentMethod,
  setCompensationMethod,
  setProvisionType,
  submitReturn,
  submitWarrantyRegistration,
  toggleSelectReturnItem,
  updatePickupVariant,
  verifyAddress,
} from "./schema/schemas";
import { submitDraftReturn } from "./schema/submit-draft-return";

// Split up the RPC schemas to prevent the RPC definition type from getting too large for TypeScript to emit

const changeAddressRpcSchema = { changeAddress };
const editReturnableItemRpcSchema = { editReturnableItem };
const createDraftReturnRpcSchema = {
  createDraftReturn,
  createOrderlessDraftReturn,
  addProductToOrderlessDraftReturn,
};
const editPendingItemQuantityRpcSchema = { editPendingItemQuantity };
const getDraftReturnRpcSchema = { getDraftReturn };
const getShippingEstimateRpcSchema = { getShippingEstimate };
const getCommentSoldRecommendedProductsRpcSchema = {
  getCommentSoldRecommendedProducts,
};
const getStripePublicKeyRpcSchema = { getStripePublicKey };
const selectShipmentMethodRpcSchema = { selectShipmentMethod };
const setCompensationMethodRpcSchema = { setCompensationMethod };
const addReturnItemRpcSchema = { addReturnItem };
const removeReturnItemRpcSchema = { removeReturnItem };
const submitReturnRpcSchema = { submitReturn };
const submitDraftReturnRpcSchema = { submitDraftReturn };
const rejectReturnItemsRpcSchema = { rejectReturnItems };
const addNewItemsRpcSchema = { addNewItems };
const removeNewItemsRpcSchema = { removeNewItems };
const verifyAddressRpcSchema = { verifyAddress };
const calculateReturnTotalsRpcSchema = { calculateReturnTotals };
const getLocationsRpcSchema = { getLocations };
const getSatisfactionResponseRpcSchema = { getSatisfactionResponse };
const saveSatisfactionResponseSchema = { saveSatisfactionResponse };
const getFaqSchema = { getFaq };
const getSharedListRpcSchema = { getSharedList };
const customerProductListSchema = {
  getCustomerProductList,
  createCustomerProductList,
};
const getSubmissionWorkflowStatusSchema = { getSubmissionWorkflowStatus };
const getWarrantyEligibleProductsSchema = { getWarrantyEligibleProducts };
const getWarrantyRegistrationFlowSchema = { getWarrantyRegistrationFlow };
const submitWarrantyRegistrationSchema = { submitWarrantyRegistration };
const fulfillmentOrderSchema = { getFulfillmentOrdersByOrder };
const getOrderIdSchema = { getOrderId };
const toggleSelectReturnItemSchema = { toggleSelectReturnItem };
const setProvisionTypeRpcSchema = { setProvisionType };
const getReturnRpcSchema = { getReturn };
const marketingSchema = {
  getSignupForm,
  submitSignupFormStep,
  logSignupFormAnalytics,
  handleMarketingEmailUnsubscribe,
};
const getOrdersRpcSchema = { getOrders };
const isShopAppDisabledRpcSchema = { isShopAppDisabled };
const updatePickupVariantSchema = { updatePickupVariant };
const getProductFilterByIdSchema = { getProductFilterById };
const getReturnCreationStatusSchema = { getReturnCreationStatus };
type RpcDefinition = typeof changeAddressRpcSchema &
  typeof createDraftReturnRpcSchema &
  typeof editReturnableItemRpcSchema &
  typeof editPendingItemQuantityRpcSchema &
  typeof getDraftReturnRpcSchema &
  typeof getShippingEstimateRpcSchema &
  typeof getCommentSoldRecommendedProductsRpcSchema &
  typeof getStripePublicKeyRpcSchema &
  typeof selectShipmentMethodRpcSchema &
  typeof setCompensationMethodRpcSchema &
  typeof addReturnItemRpcSchema &
  typeof removeReturnItemRpcSchema &
  typeof submitReturnRpcSchema &
  typeof submitDraftReturnRpcSchema &
  typeof rejectReturnItemsRpcSchema &
  typeof addNewItemsRpcSchema &
  typeof removeNewItemsRpcSchema &
  typeof verifyAddressRpcSchema &
  typeof calculateReturnTotalsRpcSchema &
  typeof getLocationsRpcSchema &
  typeof getSatisfactionResponseRpcSchema &
  typeof saveSatisfactionResponseSchema &
  typeof getFaqSchema &
  typeof getSharedListRpcSchema &
  typeof customerProductListSchema &
  typeof getWarrantyEligibleProductsSchema &
  typeof getWarrantyRegistrationFlowSchema &
  typeof submitWarrantyRegistrationSchema &
  typeof fulfillmentOrderSchema &
  typeof getOrderIdSchema &
  typeof toggleSelectReturnItemSchema &
  typeof setProvisionTypeRpcSchema &
  typeof getReturnRpcSchema &
  typeof marketingSchema &
  typeof getOrdersRpcSchema &
  typeof isShopAppDisabledRpcSchema &
  typeof updatePickupVariantSchema &
  typeof getSubmissionWorkflowStatusSchema &
  typeof getProductFilterByIdSchema &
  typeof getReturnCreationStatusSchema;

export const rpcDefinition: RpcDefinition = {
  ...changeAddressRpcSchema,
  ...createDraftReturnRpcSchema,
  ...editReturnableItemRpcSchema,
  ...editPendingItemQuantityRpcSchema,
  ...getDraftReturnRpcSchema,
  ...getShippingEstimateRpcSchema,
  ...getCommentSoldRecommendedProductsRpcSchema,
  ...getStripePublicKeyRpcSchema,
  ...selectShipmentMethodRpcSchema,
  ...setCompensationMethodRpcSchema,
  ...addReturnItemRpcSchema,
  ...removeReturnItemRpcSchema,
  ...submitReturnRpcSchema,
  ...submitDraftReturnRpcSchema,
  ...rejectReturnItemsRpcSchema,
  ...addNewItemsRpcSchema,
  ...removeNewItemsRpcSchema,
  ...verifyAddressRpcSchema,
  ...calculateReturnTotalsRpcSchema,
  ...getLocationsRpcSchema,
  ...getSatisfactionResponseRpcSchema,
  ...saveSatisfactionResponseSchema,
  ...getFaqSchema,
  ...getSharedListRpcSchema,
  ...customerProductListSchema,
  ...getWarrantyEligibleProductsSchema,
  ...getWarrantyRegistrationFlowSchema,
  ...submitWarrantyRegistrationSchema,
  ...fulfillmentOrderSchema,
  ...getOrderIdSchema,
  ...toggleSelectReturnItemSchema,
  ...setProvisionTypeRpcSchema,
  ...getReturnRpcSchema,
  ...marketingSchema,
  ...getOrdersRpcSchema,
  ...isShopAppDisabledRpcSchema,
  ...updatePickupVariantSchema,
  ...getSubmissionWorkflowStatusSchema,
  ...getProductFilterByIdSchema,
  ...getReturnCreationStatusSchema,
};

export type CustomerPortalRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;

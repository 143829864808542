import {
  ItemDetailSize,
  ReturnItemParams,
} from "@redotech/redo-customer-portal-shared/util";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeType,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";

export function ItemDetail({
  imageSrc,
  title,
  subtitle,
  quantity,
  variants,
  formattedPrice,
  isVertical = false,
  badgeText = undefined,
  size = ItemDetailSize.MEDIUM,
}: ReturnItemParams & { isVertical?: boolean; subtitle?: string }) {
  const horizontalTextString = `Qty: ${quantity} / ${formattedPrice}${variants.length > 0 ? " / " : ""}${variants.map((v) => v.value).join(" / ")}`;

  const textStrings = [];
  if (isVertical) {
    textStrings.push(...variants.map((v) => `${v.name}: ${v.value}`));
    textStrings.push(`Quantity: ${quantity}`);
  } else {
    textStrings.push(horizontalTextString);
  }
  return (
    <Flex
      align={isVertical ? "flex-start" : "center"}
      gap="2xl"
      grow={1}
      shrink={0}
    >
      <img
        alt={title}
        src={imageSrc}
        style={{ borderRadius: "6px" }}
        width={size === ItemDetailSize.SMALL ? 64 : 90}
      />
      <Flex flex={1} flexDirection="column" gap="xs">
        <Flex align="center" flexDirection="row" justify="space-between">
          <Text fontSize="md" fontWeight="medium" textColor="primary">
            {title}
          </Text>
          {isVertical ? (
            <Text
              fontSize={size === ItemDetailSize.SMALL ? "xs" : "md"}
              fontWeight="medium"
              textColor="primary"
            >
              {formattedPrice}
            </Text>
          ) : null}
        </Flex>
        <Flex flexDirection="column" gap="xxs">
          {subtitle ? (
            <Text fontSize="xs" fontWeight="thin" textColor="secondary">
              {subtitle}
            </Text>
          ) : null}
          {textStrings.map((text, index) => (
            <Text
              fontSize="xs"
              fontWeight="thin"
              key={text}
              textColor="secondary"
            >
              {text}
            </Text>
          ))}
        </Flex>
        {badgeText ? (
          <RedoBadge
            color={RedoBadgeColor.SUCCESS}
            text={badgeText}
            type={RedoBadgeType.PRIMARY}
          />
        ) : null}
      </Flex>
    </Flex>
  );
}

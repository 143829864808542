/**
 *  List of USA state postal codes (same as ISO 3166-2 subdivision code)
 */
export const usaProvinces = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "American Samoa", code: "AS" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "District of Columbia", code: "DC" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Guam", code: "GU" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
] as const;

export const USA_PROVINCE_CODES = usaProvinces.map((province) => province.code);

export type USAProvinceCode = (typeof usaProvinces)[number]["code"];
export type USAProvinceName = (typeof usaProvinces)[number]["name"];

export const USA_PROVINCES_BY_CODE = Object.fromEntries(
  usaProvinces.map((country) => [country.code, country.name]),
);
export const USA_PROVINCES_BY_NAME = Object.fromEntries(
  usaProvinces.map((country) => [country.name.toLowerCase(), country.code]),
);

export const findProvinceCode = (province: string) => {
  if (USA_PROVINCES_BY_CODE[province]) {
    return province;
  }
  const code = USA_PROVINCES_BY_NAME[province.toLowerCase()];

  if (code === undefined) {
    return province;
  }
  return code;
};

/**
 * Provinces that Shopify recognizes. A good starting place for provinces in the world.
 * source: https://matrixify.app/documentation/shopify-address-countries-and-provinces/
 */
export const shopifyProvinces = [
  { country: "Argentina", province: "Buenos Aires" },
  { country: "Argentina", province: "Catamarca" },
  { country: "Argentina", province: "Chaco" },
  { country: "Argentina", province: "Chubut" },
  { country: "Argentina", province: "Ciudad Autónoma de Buenos Aires" },
  { country: "Argentina", province: "Córdoba" },
  { country: "Argentina", province: "Corrientes" },
  { country: "Argentina", province: "Entre Ríos" },
  { country: "Argentina", province: "Formosa" },
  { country: "Argentina", province: "Jujuy" },
  { country: "Argentina", province: "La Pampa" },
  { country: "Argentina", province: "La Rioja" },
  { country: "Argentina", province: "Mendoza" },
  { country: "Argentina", province: "Misiones" },
  { country: "Argentina", province: "Neuquén" },
  { country: "Argentina", province: "Río Negro" },
  { country: "Argentina", province: "Salta" },
  { country: "Argentina", province: "San Juan" },
  { country: "Argentina", province: "San Luis" },
  { country: "Argentina", province: "Santa Cruz" },
  { country: "Argentina", province: "Santa Fe" },
  { country: "Argentina", province: "Santiago Del Estero" },
  { country: "Argentina", province: "Tierra Del Fuego" },
  { country: "Argentina", province: "Tucumán" },
  { country: "Australia", province: "Australian Capital Territory" },
  { country: "Australia", province: "New South Wales" },
  { country: "Australia", province: "Northern Territory" },
  { country: "Australia", province: "Queensland" },
  { country: "Australia", province: "South Australia" },
  { country: "Australia", province: "Tasmania" },
  { country: "Australia", province: "Victoria" },
  { country: "Australia", province: "Western Australia" },
  { country: "Brazil", province: "Acre" },
  { country: "Brazil", province: "Alagoas" },
  { country: "Brazil", province: "Amapá" },
  { country: "Brazil", province: "Amazonas" },
  { country: "Brazil", province: "Bahia" },
  { country: "Brazil", province: "Ceará" },
  { country: "Brazil", province: "Distrito Federal" },
  { country: "Brazil", province: "Espírito Santo" },
  { country: "Brazil", province: "Goiás" },
  { country: "Brazil", province: "Maranhão" },
  { country: "Brazil", province: "Mato Grosso" },
  { country: "Brazil", province: "Mato Grosso do Sul" },
  { country: "Brazil", province: "Minas Gerais" },
  { country: "Brazil", province: "Pará" },
  { country: "Brazil", province: "Paraíba" },
  { country: "Brazil", province: "Paraná" },
  { country: "Brazil", province: "Pernambuco" },
  { country: "Brazil", province: "Piauí" },
  { country: "Brazil", province: "Rio de Janeiro" },
  { country: "Brazil", province: "Rio Grande do Norte" },
  { country: "Brazil", province: "Rio Grande do Sul" },
  { country: "Brazil", province: "Rondônia" },
  { country: "Brazil", province: "Roraima" },
  { country: "Brazil", province: "Santa Catarina" },
  { country: "Brazil", province: "São Paulo" },
  { country: "Brazil", province: "Sergipe" },
  { country: "Brazil", province: "Tocantins" },
  { country: "Canada", province: "Alberta" },
  { country: "Canada", province: "British Columbia" },
  { country: "Canada", province: "Manitoba" },
  { country: "Canada", province: "New Brunswick" },
  { country: "Canada", province: "Newfoundland and Labrador" },
  { country: "Canada", province: "Northwest Territories" },
  { country: "Canada", province: "Nova Scotia" },
  { country: "Canada", province: "Nunavut" },
  { country: "Canada", province: "Ontario" },
  { country: "Canada", province: "Prince Edward Island" },
  { country: "Canada", province: "Quebec" },
  { country: "Canada", province: "Saskatchewan" },
  { country: "Canada", province: "Yukon" },
  { country: "Chile", province: "Arica and Parinacota" },
  { country: "Chile", province: "Tarapacá" },
  { country: "Chile", province: "Antofagasta" },
  { country: "Chile", province: "Atacama" },
  { country: "Chile", province: "Coquimbo" },
  { country: "Chile", province: "Valparaíso" },
  { country: "Chile", province: "Santiago" },
  { country: "Chile", province: "O’Higgins" },
  { country: "Chile", province: "Maule" },
  { country: "Chile", province: "Ñuble" },
  { country: "Chile", province: "Biobío" },
  { country: "Chile", province: "Araucanía" },
  { country: "Chile", province: "Los Ríos" },
  { country: "Chile", province: "Los Lagos" },
  { country: "Chile", province: "Aysén" },
  { country: "Chile", province: "Magallanes" },
  { country: "China", province: "Anhui" },
  { country: "China", province: "Beijing" },
  { country: "China", province: "Chongqing" },
  { country: "China", province: "Fujian" },
  { country: "China", province: "Gansu" },
  { country: "China", province: "Guangdong" },
  { country: "China", province: "Guangxi" },
  { country: "China", province: "Guizhou" },
  { country: "China", province: "Hainan" },
  { country: "China", province: "Hebei" },
  { country: "China", province: "Heilongjiang" },
  { country: "China", province: "Henan" },
  { country: "China", province: "Hubei" },
  { country: "China", province: "Hunan" },
  { country: "China", province: "Inner Mongolia" },
  { country: "China", province: "Jiangsu" },
  { country: "China", province: "Jiangxi" },
  { country: "China", province: "Jilin" },
  { country: "China", province: "Liaoning" },
  { country: "China", province: "Ningxia" },
  { country: "China", province: "Qinghai" },
  { country: "China", province: "Shaanxi" },
  { country: "China", province: "Shandong" },
  { country: "China", province: "Shanghai" },
  { country: "China", province: "Shanxi" },
  { country: "China", province: "Sichuan" },
  { country: "China", province: "Tianjin" },
  { country: "China", province: "Xinjiang" },
  { country: "China", province: "Xizang" },
  { country: "China", province: "Yunnan" },
  { country: "China", province: "Zhejiang" },
  { country: "Colombia", province: "Amazonas" },
  { country: "Colombia", province: "Antioquia" },
  { country: "Colombia", province: "Arauca" },
  { country: "Colombia", province: "Atlántico" },
  { country: "Colombia", province: "Bogotá, D.C." },
  { country: "Colombia", province: "Bolívar" },
  { country: "Colombia", province: "Boyacá" },
  { country: "Colombia", province: "Caldas" },
  { country: "Colombia", province: "Caquetá" },
  { country: "Colombia", province: "Casanare" },
  { country: "Colombia", province: "Cauca" },
  { country: "Colombia", province: "Cesar" },
  { country: "Colombia", province: "Chocó" },
  { country: "Colombia", province: "Córdoba" },
  { country: "Colombia", province: "Cundinamarca" },
  { country: "Colombia", province: "Guainía" },
  { country: "Colombia", province: "Guaviare" },
  { country: "Colombia", province: "Huila" },
  { country: "Colombia", province: "La Guajira" },
  { country: "Colombia", province: "Magdalena" },
  { country: "Colombia", province: "Meta" },
  { country: "Colombia", province: "Nariño" },
  { country: "Colombia", province: "Norte de Santander" },
  { country: "Colombia", province: "Putumayo" },
  { country: "Colombia", province: "Quindío" },
  { country: "Colombia", province: "Risaralda" },
  { country: "Colombia", province: "San Andrés, Providencia y Santa Catalina" },
  { country: "Colombia", province: "Santander" },
  { country: "Colombia", province: "Sucre" },
  { country: "Colombia", province: "Tolima" },
  { country: "Colombia", province: "Valle del Cauca" },
  { country: "Colombia", province: "Vaupés" },
  { country: "Colombia", province: "Vichada" },
  { country: "Egypt", province: "6th of October" },
  { country: "Egypt", province: "Al Sharqia" },
  { country: "Egypt", province: "Alexandria" },
  { country: "Egypt", province: "Aswan" },
  { country: "Egypt", province: "Asyut" },
  { country: "Egypt", province: "Beheira" },
  { country: "Egypt", province: "Beni Suef" },
  { country: "Egypt", province: "Cairo" },
  { country: "Egypt", province: "Dakahlia" },
  { country: "Egypt", province: "Damietta" },
  { country: "Egypt", province: "Faiyum" },
  { country: "Egypt", province: "Gharbia" },
  { country: "Egypt", province: "Giza" },
  { country: "Egypt", province: "Helwan" },
  { country: "Egypt", province: "Ismailia" },
  { country: "Egypt", province: "Kafr el-Sheikh" },
  { country: "Egypt", province: "Luxor" },
  { country: "Egypt", province: "Matrouh" },
  { country: "Egypt", province: "Minya" },
  { country: "Egypt", province: "Monufia" },
  { country: "Egypt", province: "New Valley" },
  { country: "Egypt", province: "North Sinai" },
  { country: "Egypt", province: "Port Said" },
  { country: "Egypt", province: "Qalyubia" },
  { country: "Egypt", province: "Qena" },
  { country: "Egypt", province: "Red Sea" },
  { country: "Egypt", province: "Sohag" },
  { country: "Egypt", province: "South Sinai" },
  { country: "Egypt", province: "Suez" },
  { country: "Guatemala", province: "Alta Verapaz" },
  { country: "Guatemala", province: "Baja Verapaz" },
  { country: "Guatemala", province: "Chimaltenango" },
  { country: "Guatemala", province: "Chiquimula" },
  { country: "Guatemala", province: "El Progreso" },
  { country: "Guatemala", province: "Escuintla" },
  { country: "Guatemala", province: "Guatemala" },
  { country: "Guatemala", province: "Huehuetenango" },
  { country: "Guatemala", province: "Izabal" },
  { country: "Guatemala", province: "Jalapa" },
  { country: "Guatemala", province: "Jutiapa" },
  { country: "Guatemala", province: "Petén" },
  { country: "Guatemala", province: "Quetzaltenango" },
  { country: "Guatemala", province: "Quiché" },
  { country: "Guatemala", province: "Retalhuleu" },
  { country: "Guatemala", province: "Sacatepéquez" },
  { country: "Guatemala", province: "San Marcos" },
  { country: "Guatemala", province: "Santa Rosa" },
  { country: "Guatemala", province: "Sololá" },
  { country: "Guatemala", province: "Suchitepéquez" },
  { country: "Guatemala", province: "Totonicapán" },
  { country: "Guatemala", province: "Zacapa" },
  { country: "Hong Kong", province: "Hong Kong Island" },
  { country: "Hong Kong", province: "Kowloon" },
  { country: "Hong Kong", province: "New Territories" },
  { country: "India", province: "Andaman and Nicobar Islands" },
  { country: "India", province: "Andhra Pradesh" },
  { country: "India", province: "Arunachal Pradesh" },
  { country: "India", province: "Assam" },
  { country: "India", province: "Bihar" },
  { country: "India", province: "Chandigarh" },
  { country: "India", province: "Chhattisgarh" },
  { country: "India", province: "Dadra and Nagar Haveli" },
  { country: "India", province: "Daman and Diu" },
  { country: "India", province: "Delhi" },
  { country: "India", province: "Goa" },
  { country: "India", province: "Gujarat" },
  { country: "India", province: "Haryana" },
  { country: "India", province: "Himachal Pradesh" },
  { country: "India", province: "Jammu and Kashmir" },
  { country: "India", province: "Jharkhand" },
  { country: "India", province: "Karnataka" },
  { country: "India", province: "Kerala" },
  { country: "India", province: "Ladakh" },
  { country: "India", province: "Lakshadweep" },
  { country: "India", province: "Madhya Pradesh" },
  { country: "India", province: "Maharashtra" },
  { country: "India", province: "Manipur" },
  { country: "India", province: "Meghalaya" },
  { country: "India", province: "Mizoram" },
  { country: "India", province: "Nagaland" },
  { country: "India", province: "Odisha" },
  { country: "India", province: "Puducherry" },
  { country: "India", province: "Punjab" },
  { country: "India", province: "Rajasthan" },
  { country: "India", province: "Sikkim" },
  { country: "India", province: "Tamil Nadu" },
  { country: "India", province: "Telangana" },
  { country: "India", province: "Tripura" },
  { country: "India", province: "Uttar Pradesh" },
  { country: "India", province: "Uttarakhand" },
  { country: "India", province: "West Bengal" },
  { country: "Indonesia", province: "Aceh" },
  { country: "Indonesia", province: "Bali" },
  { country: "Indonesia", province: "Bangka Belitung" },
  { country: "Indonesia", province: "Banten" },
  { country: "Indonesia", province: "Bengkulu" },
  { country: "Indonesia", province: "Gorontalo" },
  { country: "Indonesia", province: "Jakarta" },
  { country: "Indonesia", province: "Jambi" },
  { country: "Indonesia", province: "Jawa Barat" },
  { country: "Indonesia", province: "Jawa Tengah" },
  { country: "Indonesia", province: "Jawa Timur" },
  { country: "Indonesia", province: "Kalimantan Barat" },
  { country: "Indonesia", province: "Kalimantan Selatan" },
  { country: "Indonesia", province: "Kalimantan Tengah" },
  { country: "Indonesia", province: "Kalimantan Timur" },
  { country: "Indonesia", province: "Kalimantan Utara" },
  { country: "Indonesia", province: "Kepulauan Riau" },
  { country: "Indonesia", province: "Lampung" },
  { country: "Indonesia", province: "Maluku" },
  { country: "Indonesia", province: "Maluku Utara" },
  { country: "Indonesia", province: "North Sumatra" },
  { country: "Indonesia", province: "Nusa Tenggara Barat" },
  { country: "Indonesia", province: "Nusa Tenggara Timur" },
  { country: "Indonesia", province: "Papua" },
  { country: "Indonesia", province: "Papua Barat" },
  { country: "Indonesia", province: "Riau" },
  { country: "Indonesia", province: "South Sumatra" },
  { country: "Indonesia", province: "Sulawesi Barat" },
  { country: "Indonesia", province: "Sulawesi Selatan" },
  { country: "Indonesia", province: "Sulawesi Tengah" },
  { country: "Indonesia", province: "Sulawesi Tenggara" },
  { country: "Indonesia", province: "Sulawesi Utara" },
  { country: "Indonesia", province: "West Sumatra" },
  { country: "Indonesia", province: "Yogyakarta" },
  { country: "Ireland", province: "Carlow" },
  { country: "Ireland", province: "Cavan" },
  { country: "Ireland", province: "Clare" },
  { country: "Ireland", province: "Cork" },
  { country: "Ireland", province: "Donegal" },
  { country: "Ireland", province: "Dublin" },
  { country: "Ireland", province: "Galway" },
  { country: "Ireland", province: "Kerry" },
  { country: "Ireland", province: "Kildare" },
  { country: "Ireland", province: "Kilkenny" },
  { country: "Ireland", province: "Laois" },
  { country: "Ireland", province: "Leitrim" },
  { country: "Ireland", province: "Limerick" },
  { country: "Ireland", province: "Longford" },
  { country: "Ireland", province: "Louth" },
  { country: "Ireland", province: "Mayo" },
  { country: "Ireland", province: "Meath" },
  { country: "Ireland", province: "Monaghan" },
  { country: "Ireland", province: "Offaly" },
  { country: "Ireland", province: "Roscommon" },
  { country: "Ireland", province: "Sligo" },
  { country: "Ireland", province: "Tipperary" },
  { country: "Ireland", province: "Waterford" },
  { country: "Ireland", province: "Westmeath" },
  { country: "Ireland", province: "Wexford" },
  { country: "Ireland", province: "Wicklow" },
  { country: "Italy", province: "Agrigento" },
  { country: "Italy", province: "Alessandria" },
  { country: "Italy", province: "Ancona" },
  { country: "Italy", province: "Aosta" },
  { country: "Italy", province: "Arezzo" },
  { country: "Italy", province: "Ascoli Piceno" },
  { country: "Italy", province: "Asti" },
  { country: "Italy", province: "Avellino" },
  { country: "Italy", province: "Bari" },
  { country: "Italy", province: "Barletta-Andria-Trani" },
  { country: "Italy", province: "Belluno" },
  { country: "Italy", province: "Benevento" },
  { country: "Italy", province: "Bergamo" },
  { country: "Italy", province: "Biella" },
  { country: "Italy", province: "Bologna" },
  { country: "Italy", province: "Bolzano" },
  { country: "Italy", province: "Brescia" },
  { country: "Italy", province: "Brindisi" },
  { country: "Italy", province: "Cagliari" },
  { country: "Italy", province: "Caltanissetta" },
  { country: "Italy", province: "Campobasso" },
  { country: "Italy", province: "Carbonia-Iglesias" },
  { country: "Italy", province: "Caserta" },
  { country: "Italy", province: "Catania" },
  { country: "Italy", province: "Catanzaro" },
  { country: "Italy", province: "Chieti" },
  { country: "Italy", province: "Como" },
  { country: "Italy", province: "Cosenza" },
  { country: "Italy", province: "Cremona" },
  { country: "Italy", province: "Crotone" },
  { country: "Italy", province: "Cuneo" },
  { country: "Italy", province: "Enna" },
  { country: "Italy", province: "Fermo" },
  { country: "Italy", province: "Ferrara" },
  { country: "Italy", province: "Firenze" },
  { country: "Italy", province: "Foggia" },
  { country: "Italy", province: "Forlì-Cesena" },
  { country: "Italy", province: "Frosinone" },
  { country: "Italy", province: "Genova" },
  { country: "Italy", province: "Gorizia" },
  { country: "Italy", province: "Grosseto" },
  { country: "Italy", province: "Imperia" },
  { country: "Italy", province: "Isernia" },
  { country: "Italy", province: "L’Aquila" },
  { country: "Italy", province: "La Spezia" },
  { country: "Italy", province: "Latina" },
  { country: "Italy", province: "Lecce" },
  { country: "Italy", province: "Lecco" },
  { country: "Italy", province: "Livorno" },
  { country: "Italy", province: "Lodi" },
  { country: "Italy", province: "Lucca" },
  { country: "Italy", province: "Macerata" },
  { country: "Italy", province: "Mantova" },
  { country: "Italy", province: "Massa-Carrara" },
  { country: "Italy", province: "Matera" },
  { country: "Italy", province: "Medio Campidano" },
  { country: "Italy", province: "Messina" },
  { country: "Italy", province: "Milano" },
  { country: "Italy", province: "Modena" },
  { country: "Italy", province: "Monza e Brianza" },
  { country: "Italy", province: "Napoli" },
  { country: "Italy", province: "Novara" },
  { country: "Italy", province: "Nuoro" },
  { country: "Italy", province: "Ogliastra" },
  { country: "Italy", province: "Olbia-Tempio" },
  { country: "Italy", province: "Oristano" },
  { country: "Italy", province: "Padova" },
  { country: "Italy", province: "Palermo" },
  { country: "Italy", province: "Parma" },
  { country: "Italy", province: "Pavia" },
  { country: "Italy", province: "Perugia" },
  { country: "Italy", province: "Pesaro e Urbino" },
  { country: "Italy", province: "Pescara" },
  { country: "Italy", province: "Piacenza" },
  { country: "Italy", province: "Pisa" },
  { country: "Italy", province: "Pistoia" },
  { country: "Italy", province: "Pordenone" },
  { country: "Italy", province: "Potenza" },
  { country: "Italy", province: "Prato" },
  { country: "Italy", province: "Ragusa" },
  { country: "Italy", province: "Ravenna" },
  { country: "Italy", province: "Reggio Calabria" },
  { country: "Italy", province: "Reggio Emilia" },
  { country: "Italy", province: "Rieti" },
  { country: "Italy", province: "Rimini" },
  { country: "Italy", province: "Roma" },
  { country: "Italy", province: "Rovigo" },
  { country: "Italy", province: "Salerno" },
  { country: "Italy", province: "Sassari" },
  { country: "Italy", province: "Savona" },
  { country: "Italy", province: "Siena" },
  { country: "Italy", province: "Siracusa" },
  { country: "Italy", province: "Sondrio" },
  { country: "Italy", province: "Taranto" },
  { country: "Italy", province: "Teramo" },
  { country: "Italy", province: "Terni" },
  { country: "Italy", province: "Torino" },
  { country: "Italy", province: "Trapani" },
  { country: "Italy", province: "Trento" },
  { country: "Italy", province: "Treviso" },
  { country: "Italy", province: "Trieste" },
  { country: "Italy", province: "Udine" },
  { country: "Italy", province: "Varese" },
  { country: "Italy", province: "Venezia" },
  { country: "Italy", province: "Verbano-Cusio-Ossola" },
  { country: "Italy", province: "Vercelli" },
  { country: "Italy", province: "Verona" },
  { country: "Italy", province: "Vibo Valentia" },
  { country: "Italy", province: "Vicenza" },
  { country: "Italy", province: "Viterbo" },
  { country: "Japan", province: "Hokkaidō" },
  { country: "Japan", province: "Aomori" },
  { country: "Japan", province: "Iwate" },
  { country: "Japan", province: "Miyagi" },
  { country: "Japan", province: "Akita" },
  { country: "Japan", province: "Yamagata" },
  { country: "Japan", province: "Fukushima" },
  { country: "Japan", province: "Ibaraki" },
  { country: "Japan", province: "Tochigi" },
  { country: "Japan", province: "Gunma" },
  { country: "Japan", province: "Saitama" },
  { country: "Japan", province: "Chiba" },
  { country: "Japan", province: "Tōkyō" },
  { country: "Japan", province: "Kanagawa" },
  { country: "Japan", province: "Niigata" },
  { country: "Japan", province: "Toyama" },
  { country: "Japan", province: "Ishikawa" },
  { country: "Japan", province: "Fukui" },
  { country: "Japan", province: "Yamanashi" },
  { country: "Japan", province: "Nagano" },
  { country: "Japan", province: "Gifu" },
  { country: "Japan", province: "Shizuoka" },
  { country: "Japan", province: "Aichi" },
  { country: "Japan", province: "Mie" },
  { country: "Japan", province: "Shiga" },
  { country: "Japan", province: "Kyōto" },
  { country: "Japan", province: "Ōsaka" },
  { country: "Japan", province: "Hyōgo" },
  { country: "Japan", province: "Nara" },
  { country: "Japan", province: "Wakayama" },
  { country: "Japan", province: "Tottori" },
  { country: "Japan", province: "Shimane" },
  { country: "Japan", province: "Okayama" },
  { country: "Japan", province: "Hiroshima" },
  { country: "Japan", province: "Yamaguchi" },
  { country: "Japan", province: "Tokushima" },
  { country: "Japan", province: "Kagawa" },
  { country: "Japan", province: "Ehime" },
  { country: "Japan", province: "Kōchi" },
  { country: "Japan", province: "Fukuoka" },
  { country: "Japan", province: "Saga" },
  { country: "Japan", province: "Nagasaki" },
  { country: "Japan", province: "Kumamoto" },
  { country: "Japan", province: "Ōita" },
  { country: "Japan", province: "Miyazaki" },
  { country: "Japan", province: "Kagoshima" },
  { country: "Japan", province: "Okinawa" },
  { country: "Malaysia", province: "Johor" },
  { country: "Malaysia", province: "Kedah" },
  { country: "Malaysia", province: "Kelantan" },
  { country: "Malaysia", province: "Kuala Lumpur" },
  { country: "Malaysia", province: "Labuan" },
  { country: "Malaysia", province: "Melaka" },
  { country: "Malaysia", province: "Negeri Sembilan" },
  { country: "Malaysia", province: "Pahang" },
  { country: "Malaysia", province: "Penang" },
  { country: "Malaysia", province: "Perak" },
  { country: "Malaysia", province: "Perlis" },
  { country: "Malaysia", province: "Putrajaya" },
  { country: "Malaysia", province: "Sabah" },
  { country: "Malaysia", province: "Sarawak" },
  { country: "Malaysia", province: "Selangor" },
  { country: "Malaysia", province: "Terengganu" },
  { country: "Mexico", province: "Aguascalientes" },
  { country: "Mexico", province: "Baja California" },
  { country: "Mexico", province: "Baja California Sur" },
  { country: "Mexico", province: "Campeche" },
  { country: "Mexico", province: "Chiapas" },
  { country: "Mexico", province: "Chihuahua" },
  { country: "Mexico", province: "Ciudad de México" },
  { country: "Mexico", province: "Coahuila" },
  { country: "Mexico", province: "Colima" },
  { country: "Mexico", province: "Durango" },
  { country: "Mexico", province: "Guanajuato" },
  { country: "Mexico", province: "Guerrero" },
  { country: "Mexico", province: "Hidalgo" },
  { country: "Mexico", province: "Jalisco" },
  { country: "Mexico", province: "México" },
  { country: "Mexico", province: "Michoacán" },
  { country: "Mexico", province: "Morelos" },
  { country: "Mexico", province: "Nayarit" },
  { country: "Mexico", province: "Nuevo León" },
  { country: "Mexico", province: "Oaxaca" },
  { country: "Mexico", province: "Puebla" },
  { country: "Mexico", province: "Querétaro" },
  { country: "Mexico", province: "Quintana Roo" },
  { country: "Mexico", province: "San Luis Potosí" },
  { country: "Mexico", province: "Sinaloa" },
  { country: "Mexico", province: "Sonora" },
  { country: "Mexico", province: "Tabasco" },
  { country: "Mexico", province: "Tamaulipas" },
  { country: "Mexico", province: "Tlaxcala" },
  { country: "Mexico", province: "Veracruz" },
  { country: "Mexico", province: "Yucatán" },
  { country: "Mexico", province: "Zacatecas" },
  { country: "New Zealand", province: "Auckland" },
  { country: "New Zealand", province: "Bay of Plenty" },
  { country: "New Zealand", province: "Canterbury" },
  { country: "New Zealand", province: "Chatham Islands" },
  { country: "New Zealand", province: "Gisborne" },
  { country: "New Zealand", province: "Hawke’s Bay" },
  { country: "New Zealand", province: "Manawatu-Wanganui" },
  { country: "New Zealand", province: "Marlborough" },
  { country: "New Zealand", province: "Nelson" },
  { country: "New Zealand", province: "Northland" },
  { country: "New Zealand", province: "Otago" },
  { country: "New Zealand", province: "Southland" },
  { country: "New Zealand", province: "Taranaki" },
  { country: "New Zealand", province: "Tasman" },
  { country: "New Zealand", province: "Waikato" },
  { country: "New Zealand", province: "Wellington" },
  { country: "New Zealand", province: "West Coast" },
  { country: "Nigeria", province: "Abia" },
  { country: "Nigeria", province: "Abuja Federal Capital Territory" },
  { country: "Nigeria", province: "Adamawa" },
  { country: "Nigeria", province: "Akwa Ibom" },
  { country: "Nigeria", province: "Anambra" },
  { country: "Nigeria", province: "Bauchi" },
  { country: "Nigeria", province: "Bayelsa" },
  { country: "Nigeria", province: "Benue" },
  { country: "Nigeria", province: "Borno" },
  { country: "Nigeria", province: "Cross River" },
  { country: "Nigeria", province: "Delta" },
  { country: "Nigeria", province: "Ebonyi" },
  { country: "Nigeria", province: "Edo" },
  { country: "Nigeria", province: "Ekiti" },
  { country: "Nigeria", province: "Enugu" },
  { country: "Nigeria", province: "Gombe" },
  { country: "Nigeria", province: "Imo" },
  { country: "Nigeria", province: "Jigawa" },
  { country: "Nigeria", province: "Kaduna" },
  { country: "Nigeria", province: "Kano" },
  { country: "Nigeria", province: "Katsina" },
  { country: "Nigeria", province: "Kebbi" },
  { country: "Nigeria", province: "Kogi" },
  { country: "Nigeria", province: "Kwara" },
  { country: "Nigeria", province: "Lagos" },
  { country: "Nigeria", province: "Nasarawa" },
  { country: "Nigeria", province: "Niger" },
  { country: "Nigeria", province: "Ogun" },
  { country: "Nigeria", province: "Ondo" },
  { country: "Nigeria", province: "Osun" },
  { country: "Nigeria", province: "Oyo" },
  { country: "Nigeria", province: "Plateau" },
  { country: "Nigeria", province: "Rivers" },
  { country: "Nigeria", province: "Sokoto" },
  { country: "Nigeria", province: "Taraba" },
  { country: "Nigeria", province: "Yobe" },
  { country: "Nigeria", province: "Zamfara" },
  { country: "Panama", province: "Bocas del Toro" },
  { country: "Panama", province: "Chiriquí" },
  { country: "Panama", province: "Coclé" },
  { country: "Panama", province: "Colón" },
  { country: "Panama", province: "Darién" },
  { country: "Panama", province: "Emberá" },
  { country: "Panama", province: "Herrera" },
  { country: "Panama", province: "Kuna Yala" },
  { country: "Panama", province: "Los Santos" },
  { country: "Panama", province: "Ngöbe-Buglé" },
  { country: "Panama", province: "Panamá" },
  { country: "Panama", province: "Panamá Oeste" },
  { country: "Panama", province: "Veraguas" },
  { country: "Peru", province: "Amazonas" },
  { country: "Peru", province: "Áncash" },
  { country: "Peru", province: "Apurímac" },
  { country: "Peru", province: "Arequipa" },
  { country: "Peru", province: "Ayacucho" },
  { country: "Peru", province: "Cajamarca" },
  { country: "Peru", province: "Callao" },
  { country: "Peru", province: "Cuzco" },
  { country: "Peru", province: "Huancavelica" },
  { country: "Peru", province: "Huánuco" },
  { country: "Peru", province: "Ica" },
  { country: "Peru", province: "Junín" },
  { country: "Peru", province: "La Libertad" },
  { country: "Peru", province: "Lambayeque" },
  { country: "Peru", province: "Lima (departamento)" },
  { country: "Peru", province: "Lima (provincia)" },
  { country: "Peru", province: "Loreto" },
  { country: "Peru", province: "Madre de Dios" },
  { country: "Peru", province: "Moquegua" },
  { country: "Peru", province: "Pasco" },
  { country: "Peru", province: "Piura" },
  { country: "Peru", province: "Puno" },
  { country: "Peru", province: "San Martín" },
  { country: "Peru", province: "Tacna" },
  { country: "Peru", province: "Tumbes" },
  { country: "Peru", province: "Ucayali" },
  { country: "Philippines", province: "Abra" },
  { country: "Philippines", province: "Agusan del Norte" },
  { country: "Philippines", province: "Agusan del Sur" },
  { country: "Philippines", province: "Aklan" },
  { country: "Philippines", province: "Albay" },
  { country: "Philippines", province: "Antique" },
  { country: "Philippines", province: "Apayao" },
  { country: "Philippines", province: "Aurora" },
  { country: "Philippines", province: "Basilan" },
  { country: "Philippines", province: "Bataan" },
  { country: "Philippines", province: "Batanes" },
  { country: "Philippines", province: "Batangas" },
  { country: "Philippines", province: "Benguet" },
  { country: "Philippines", province: "Biliran" },
  { country: "Philippines", province: "Bohol" },
  { country: "Philippines", province: "Bukidnon" },
  { country: "Philippines", province: "Bulacan" },
  { country: "Philippines", province: "Cagayan" },
  { country: "Philippines", province: "Camarines Norte" },
  { country: "Philippines", province: "Camarines Sur" },
  { country: "Philippines", province: "Camiguin" },
  { country: "Philippines", province: "Capiz" },
  { country: "Philippines", province: "Catanduanes" },
  { country: "Philippines", province: "Cavite" },
  { country: "Philippines", province: "Cebu" },
  { country: "Philippines", province: "Cotabato" },
  { country: "Philippines", province: "Davao de Oro" },
  { country: "Philippines", province: "Davao del Norte" },
  { country: "Philippines", province: "Davao del Sur" },
  { country: "Philippines", province: "Davao Occidental" },
  { country: "Philippines", province: "Davao Oriental" },
  { country: "Philippines", province: "Dinagat Islands" },
  { country: "Philippines", province: "Eastern Samar" },
  { country: "Philippines", province: "Guimaras" },
  { country: "Philippines", province: "Ifugao" },
  { country: "Philippines", province: "Ilocos Norte" },
  { country: "Philippines", province: "Ilocos Sur" },
  { country: "Philippines", province: "Iloilo" },
  { country: "Philippines", province: "Isabela" },
  { country: "Philippines", province: "Kalinga" },
  { country: "Philippines", province: "La Union" },
  { country: "Philippines", province: "Laguna" },
  { country: "Philippines", province: "Lanao del Norte" },
  { country: "Philippines", province: "Lanao del Sur" },
  { country: "Philippines", province: "Leyte" },
  { country: "Philippines", province: "Maguindanao" },
  { country: "Philippines", province: "Marinduque" },
  { country: "Philippines", province: "Masbate" },
  { country: "Philippines", province: "Metro Manila" },
  { country: "Philippines", province: "Misamis Occidental" },
  { country: "Philippines", province: "Misamis Oriental" },
  { country: "Philippines", province: "Mountain Province" },
  { country: "Philippines", province: "Negros Occidental" },
  { country: "Philippines", province: "Negros Oriental" },
  { country: "Philippines", province: "Northern Samar" },
  { country: "Philippines", province: "Nueva Ecija" },
  { country: "Philippines", province: "Nueva Vizcaya" },
  { country: "Philippines", province: "Occidental Mindoro" },
  { country: "Philippines", province: "Oriental Mindoro" },
  { country: "Philippines", province: "Palawan" },
  { country: "Philippines", province: "Pampanga" },
  { country: "Philippines", province: "Pangasinan" },
  { country: "Philippines", province: "Quezon" },
  { country: "Philippines", province: "Quirino" },
  { country: "Philippines", province: "Rizal" },
  { country: "Philippines", province: "Romblon" },
  { country: "Philippines", province: "Samar" },
  { country: "Philippines", province: "Sarangani" },
  { country: "Philippines", province: "Siquijor" },
  { country: "Philippines", province: "Sorsogon" },
  { country: "Philippines", province: "South Cotabato" },
  { country: "Philippines", province: "Southern Leyte" },
  { country: "Philippines", province: "Sultan Kudarat" },
  { country: "Philippines", province: "Sulu" },
  { country: "Philippines", province: "Surigao del Norte" },
  { country: "Philippines", province: "Surigao del Sur" },
  { country: "Philippines", province: "Tarlac" },
  { country: "Philippines", province: "Tawi-Tawi" },
  { country: "Philippines", province: "Zambales" },
  { country: "Philippines", province: "Zamboanga del Norte" },
  { country: "Philippines", province: "Zamboanga del Sur" },
  { country: "Philippines", province: "Zamboanga Sibugay" },
  { country: "Portugal", province: "Açores" },
  { country: "Portugal", province: "Aveiro" },
  { country: "Portugal", province: "Beja" },
  { country: "Portugal", province: "Braga" },
  { country: "Portugal", province: "Bragança" },
  { country: "Portugal", province: "Castelo Branco" },
  { country: "Portugal", province: "Coimbra" },
  { country: "Portugal", province: "Évora" },
  { country: "Portugal", province: "Faro" },
  { country: "Portugal", province: "Guarda" },
  { country: "Portugal", province: "Leiria" },
  { country: "Portugal", province: "Lisboa" },
  { country: "Portugal", province: "Madeira" },
  { country: "Portugal", province: "Portalegre" },
  { country: "Portugal", province: "Porto" },
  { country: "Portugal", province: "Santarém" },
  { country: "Portugal", province: "Setúbal" },
  { country: "Portugal", province: "Viana do Castelo" },
  { country: "Portugal", province: "Vila Real" },
  { country: "Portugal", province: "Viseu" },
  { country: "Romania", province: "Alba" },
  { country: "Romania", province: "Arad" },
  { country: "Romania", province: "Argeș" },
  { country: "Romania", province: "Bacău" },
  { country: "Romania", province: "Bihor" },
  { country: "Romania", province: "Bistrița-Năsăud" },
  { country: "Romania", province: "Botoșani" },
  { country: "Romania", province: "Brașov" },
  { country: "Romania", province: "Brăila" },
  { country: "Romania", province: "București" },
  { country: "Romania", province: "Buzău" },
  { country: "Romania", province: "Călărași" },
  { country: "Romania", province: "Caraș-Severin" },
  { country: "Romania", province: "Cluj" },
  { country: "Romania", province: "Constanța" },
  { country: "Romania", province: "Covasna" },
  { country: "Romania", province: "Dâmbovița" },
  { country: "Romania", province: "Dolj" },
  { country: "Romania", province: "Galați" },
  { country: "Romania", province: "Giurgiu" },
  { country: "Romania", province: "Gorj" },
  { country: "Romania", province: "Harghita" },
  { country: "Romania", province: "Hunedoara" },
  { country: "Romania", province: "Iași" },
  { country: "Romania", province: "Ialomița" },
  { country: "Romania", province: "Ilfov" },
  { country: "Romania", province: "Maramureș" },
  { country: "Romania", province: "Mehedinți" },
  { country: "Romania", province: "Mureș" },
  { country: "Romania", province: "Neamț" },
  { country: "Romania", province: "Olt" },
  { country: "Romania", province: "Prahova" },
  { country: "Romania", province: "Sălaj" },
  { country: "Romania", province: "Satu Mare" },
  { country: "Romania", province: "Sibiu" },
  { country: "Romania", province: "Suceava" },
  { country: "Romania", province: "Teleorman" },
  { country: "Romania", province: "Timiș" },
  { country: "Romania", province: "Tulcea" },
  { country: "Romania", province: "Vâlcea" },
  { country: "Romania", province: "Vaslui" },
  { country: "Romania", province: "Vrancea" },
  { country: "Russia", province: "Republic of Adygeya" },
  { country: "Russia", province: "Altai Republic" },
  { country: "Russia", province: "Altai Krai" },
  { country: "Russia", province: "Amur Oblast" },
  { country: "Russia", province: "Arkhangelsk Oblast" },
  { country: "Russia", province: "Astrakhan Oblast" },
  { country: "Russia", province: "Republic of Bashkortostan" },
  { country: "Russia", province: "Belgorod Oblast" },
  { country: "Russia", province: "Bryansk Oblast" },
  { country: "Russia", province: "Republic of Buryatia" },
  { country: "Russia", province: "Chechen Republic" },
  { country: "Russia", province: "Chelyabinsk Oblast" },
  { country: "Russia", province: "Chukotka Autonomous Okrug" },
  { country: "Russia", province: "Chuvash Republic" },
  { country: "Russia", province: "Republic of Dagestan" },
  { country: "Russia", province: "Republic of Ingushetia" },
  { country: "Russia", province: "Irkutsk Oblast" },
  { country: "Russia", province: "Ivanovo Oblast" },
  { country: "Russia", province: "Jewish Autonomous Oblast" },
  { country: "Russia", province: "Kabardino-Balkarian Republic" },
  { country: "Russia", province: "Kaliningrad Oblast" },
  { country: "Russia", province: "Republic of Kalmykia" },
  { country: "Russia", province: "Kaluga Oblast" },
  { country: "Russia", province: "Kamchatka Krai" },
  { country: "Russia", province: "Karachay–Cherkess Republic" },
  { country: "Russia", province: "Republic of Karelia" },
  { country: "Russia", province: "Kemerovo Oblast" },
  { country: "Russia", province: "Khabarovsk Krai" },
  { country: "Russia", province: "Republic of Khakassia" },
  { country: "Russia", province: "Khanty-Mansi Autonomous Okrug" },
  { country: "Russia", province: "Kirov Oblast" },
  { country: "Russia", province: "Komi Republic" },
  { country: "Russia", province: "Kostroma Oblast" },
  { country: "Russia", province: "Krasnodar Krai" },
  { country: "Russia", province: "Krasnoyarsk Krai" },
  { country: "Russia", province: "Kurgan Oblast" },
  { country: "Russia", province: "Kursk Oblast" },
  { country: "Russia", province: "Leningrad Oblast" },
  { country: "Russia", province: "Lipetsk Oblast" },
  { country: "Russia", province: "Magadan Oblast" },
  { country: "Russia", province: "Mari El Republic" },
  { country: "Russia", province: "Republic of Mordovia" },
  { country: "Russia", province: "Moscow" },
  { country: "Russia", province: "Moscow Oblast" },
  { country: "Russia", province: "Murmansk Oblast" },
  { country: "Russia", province: "Nizhny Novgorod Oblast" },
  { country: "Russia", province: "Novgorod Oblast" },
  { country: "Russia", province: "Novosibirsk Oblast" },
  { country: "Russia", province: "Omsk Oblast" },
  { country: "Russia", province: "Orenburg Oblast" },
  { country: "Russia", province: "Oryol Oblast" },
  { country: "Russia", province: "Penza Oblast" },
  { country: "Russia", province: "Perm Krai" },
  { country: "Russia", province: "Primorsky Krai" },
  { country: "Russia", province: "Pskov Oblast" },
  { country: "Russia", province: "Rostov Oblast" },
  { country: "Russia", province: "Ryazan Oblast" },
  { country: "Russia", province: "Saint Petersburg" },
  { country: "Russia", province: "Sakha Republic (Yakutia)" },
  { country: "Russia", province: "Sakhalin Oblast" },
  { country: "Russia", province: "Samara Oblast" },
  { country: "Russia", province: "Saratov Oblast" },
  { country: "Russia", province: "Republic of North Ossetia–Alania" },
  { country: "Russia", province: "Smolensk Oblast" },
  { country: "Russia", province: "Stavropol Krai" },
  { country: "Russia", province: "Sverdlovsk Oblast" },
  { country: "Russia", province: "Tambov Oblast" },
  { country: "Russia", province: "Republic of Tatarstan" },
  { country: "Russia", province: "Tomsk Oblast" },
  { country: "Russia", province: "Tula Oblast" },
  { country: "Russia", province: "Tver Oblast" },
  { country: "Russia", province: "Tyumen Oblast" },
  { country: "Russia", province: "Tyva Republic" },
  { country: "Russia", province: "Udmurtia" },
  { country: "Russia", province: "Ulyanovsk Oblast" },
  { country: "Russia", province: "Vladimir Oblast" },
  { country: "Russia", province: "Volgograd Oblast" },
  { country: "Russia", province: "Vologda Oblast" },
  { country: "Russia", province: "Voronezh Oblast" },
  { country: "Russia", province: "Yamalo-Nenets Autonomous Okrug" },
  { country: "Russia", province: "Yaroslavl Oblast" },
  { country: "Russia", province: "Zabaykalsky Krai" },
  { country: "South Africa", province: "Eastern Cape" },
  { country: "South Africa", province: "Free State" },
  { country: "South Africa", province: "Gauteng" },
  { country: "South Africa", province: "KwaZulu-Natal" },
  { country: "South Africa", province: "Limpopo" },
  { country: "South Africa", province: "Mpumalanga" },
  { country: "South Africa", province: "North West" },
  { country: "South Africa", province: "Northern Cape" },
  { country: "South Africa", province: "Western Cape" },
  { country: "South Korea", province: "Busan" },
  { country: "South Korea", province: "Chungbuk" },
  { country: "South Korea", province: "Chungnam" },
  { country: "South Korea", province: "Daegu" },
  { country: "South Korea", province: "Daejeon" },
  { country: "South Korea", province: "Gangwon" },
  { country: "South Korea", province: "Gwangju" },
  { country: "South Korea", province: "Gyeongbuk" },
  { country: "South Korea", province: "Gyeonggi" },
  { country: "South Korea", province: "Gyeongnam" },
  { country: "South Korea", province: "Incheon" },
  { country: "South Korea", province: "Jeju" },
  { country: "South Korea", province: "Jeonbuk" },
  { country: "South Korea", province: "Jeonnam" },
  { country: "South Korea", province: "Sejong" },
  { country: "South Korea", province: "Seoul" },
  { country: "South Korea", province: "Ulsan" },
  { country: "Spain", province: "A Coruña" },
  { country: "Spain", province: "Álava" },
  { country: "Spain", province: "Albacete" },
  { country: "Spain", province: "Alicante" },
  { country: "Spain", province: "Almería" },
  { country: "Spain", province: "Asturias" },
  { country: "Spain", province: "Ávila" },
  { country: "Spain", province: "Badajoz" },
  { country: "Spain", province: "Balears" },
  { country: "Spain", province: "Barcelona" },
  { country: "Spain", province: "Burgos" },
  { country: "Spain", province: "Cáceres" },
  { country: "Spain", province: "Cádiz" },
  { country: "Spain", province: "Cantabria" },
  { country: "Spain", province: "Castellón" },
  { country: "Spain", province: "Ceuta" },
  { country: "Spain", province: "Ciudad Real" },
  { country: "Spain", province: "Córdoba" },
  { country: "Spain", province: "Cuenca" },
  { country: "Spain", province: "Girona" },
  { country: "Spain", province: "Granada" },
  { country: "Spain", province: "Guadalajara" },
  { country: "Spain", province: "Guipúzcoa" },
  { country: "Spain", province: "Huelva" },
  { country: "Spain", province: "Huesca" },
  { country: "Spain", province: "Jaén" },
  { country: "Spain", province: "La Rioja" },
  { country: "Spain", province: "Las Palmas" },
  { country: "Spain", province: "León" },
  { country: "Spain", province: "Lleida" },
  { country: "Spain", province: "Lugo" },
  { country: "Spain", province: "Madrid" },
  { country: "Spain", province: "Málaga" },
  { country: "Spain", province: "Melilla" },
  { country: "Spain", province: "Murcia" },
  { country: "Spain", province: "Navarra" },
  { country: "Spain", province: "Ourense" },
  { country: "Spain", province: "Palencia" },
  { country: "Spain", province: "Pontevedra" },
  { country: "Spain", province: "Salamanca" },
  { country: "Spain", province: "Santa Cruz de Tenerife" },
  { country: "Spain", province: "Segovia" },
  { country: "Spain", province: "Sevilla" },
  { country: "Spain", province: "Soria" },
  { country: "Spain", province: "Tarragona" },
  { country: "Spain", province: "Teruel" },
  { country: "Spain", province: "Toledo" },
  { country: "Spain", province: "Valencia" },
  { country: "Spain", province: "Valladolid" },
  { country: "Spain", province: "Vizcaya" },
  { country: "Spain", province: "Zamora" },
  { country: "Spain", province: "Zaragoza" },
  { country: "Thailand", province: "Amnat Charoen" },
  { country: "Thailand", province: "Ang Thong" },
  { country: "Thailand", province: "Bangkok" },
  { country: "Thailand", province: "Bueng Kan" },
  { country: "Thailand", province: "Buriram" },
  { country: "Thailand", province: "Chachoengsao" },
  { country: "Thailand", province: "Chai Nat" },
  { country: "Thailand", province: "Chaiyaphum" },
  { country: "Thailand", province: "Chanthaburi" },
  { country: "Thailand", province: "Chiang Mai" },
  { country: "Thailand", province: "Chiang Rai" },
  { country: "Thailand", province: "Chon Buri" },
  { country: "Thailand", province: "Chumphon" },
  { country: "Thailand", province: "Kalasin" },
  { country: "Thailand", province: "Kamphaeng Phet" },
  { country: "Thailand", province: "Kanchanaburi" },
  { country: "Thailand", province: "Khon Kaen" },
  { country: "Thailand", province: "Krabi" },
  { country: "Thailand", province: "Lampang" },
  { country: "Thailand", province: "Lamphun" },
  { country: "Thailand", province: "Loei" },
  { country: "Thailand", province: "Lopburi" },
  { country: "Thailand", province: "Mae Hong Son" },
  { country: "Thailand", province: "Maha Sarakham" },
  { country: "Thailand", province: "Mukdahan" },
  { country: "Thailand", province: "Nakhon Nayok" },
  { country: "Thailand", province: "Nakhon Pathom" },
  { country: "Thailand", province: "Nakhon Phanom" },
  { country: "Thailand", province: "Nakhon Ratchasima" },
  { country: "Thailand", province: "Nakhon Sawan" },
  { country: "Thailand", province: "Nakhon Si Thammarat" },
  { country: "Thailand", province: "Nan" },
  { country: "Thailand", province: "Narathiwat" },
  { country: "Thailand", province: "Nong Bua Lam Phu" },
  { country: "Thailand", province: "Nong Khai" },
  { country: "Thailand", province: "Nonthaburi" },
  { country: "Thailand", province: "Pathum Thani" },
  { country: "Thailand", province: "Pattani" },
  { country: "Thailand", province: "Pattaya" },
  { country: "Thailand", province: "Phangnga" },
  { country: "Thailand", province: "Phatthalung" },
  { country: "Thailand", province: "Phayao" },
  { country: "Thailand", province: "Phetchabun" },
  { country: "Thailand", province: "Phetchaburi" },
  { country: "Thailand", province: "Phichit" },
  { country: "Thailand", province: "Phitsanulok" },
  { country: "Thailand", province: "Phra Nakhon Si Ayutthaya" },
  { country: "Thailand", province: "Phrae" },
  { country: "Thailand", province: "Phuket" },
  { country: "Thailand", province: "Prachin Buri" },
  { country: "Thailand", province: "Prachuap Khiri Khan" },
  { country: "Thailand", province: "Ranong" },
  { country: "Thailand", province: "Ratchaburi" },
  { country: "Thailand", province: "Rayong" },
  { country: "Thailand", province: "Roi Et" },
  { country: "Thailand", province: "Sa Kaeo" },
  { country: "Thailand", province: "Sakon Nakhon" },
  { country: "Thailand", province: "Samut Prakan" },
  { country: "Thailand", province: "Samut Sakhon" },
  { country: "Thailand", province: "Samut Songkhram" },
  { country: "Thailand", province: "Saraburi" },
  { country: "Thailand", province: "Satun" },
  { country: "Thailand", province: "Sing Buri" },
  { country: "Thailand", province: "Sisaket" },
  { country: "Thailand", province: "Songkhla" },
  { country: "Thailand", province: "Sukhothai" },
  { country: "Thailand", province: "Suphan Buri" },
  { country: "Thailand", province: "Surat Thani" },
  { country: "Thailand", province: "Surin" },
  { country: "Thailand", province: "Tak" },
  { country: "Thailand", province: "Trang" },
  { country: "Thailand", province: "Trat" },
  { country: "Thailand", province: "Ubon Ratchathani" },
  { country: "Thailand", province: "Udon Thani" },
  { country: "Thailand", province: "Uthai Thani" },
  { country: "Thailand", province: "Uttaradit" },
  { country: "Thailand", province: "Yala" },
  { country: "Thailand", province: "Yasothon" },
  { country: "United Arab Emirates", province: "Abu Dhabi" },
  { country: "United Arab Emirates", province: "Ajman" },
  { country: "United Arab Emirates", province: "Dubai" },
  { country: "United Arab Emirates", province: "Fujairah" },
  { country: "United Arab Emirates", province: "Ras al-Khaimah" },
  { country: "United Arab Emirates", province: "Sharjah" },
  { country: "United Arab Emirates", province: "Umm al-Quwain" },
  { country: "United Kingdom", province: "British Forces" },
  { country: "United Kingdom", province: "England" },
  { country: "United Kingdom", province: "Northern Ireland" },
  { country: "United Kingdom", province: "Scotland" },
  { country: "United Kingdom", province: "Wales" },
  { country: "United States", province: "Alabama" },
  { country: "United States", province: "Alaska" },
  { country: "United States", province: "American Samoa" },
  { country: "United States", province: "Arizona" },
  { country: "United States", province: "Arkansas" },
  { country: "United States", province: "California" },
  { country: "United States", province: "Colorado" },
  { country: "United States", province: "Connecticut" },
  { country: "United States", province: "Delaware" },
  { country: "United States", province: "District of Columbia" },
  { country: "United States", province: "Federated States of Micronesia" },
  { country: "United States", province: "Florida" },
  { country: "United States", province: "Georgia" },
  { country: "United States", province: "Guam" },
  { country: "United States", province: "Hawaii" },
  { country: "United States", province: "Idaho" },
  { country: "United States", province: "Illinois" },
  { country: "United States", province: "Indiana" },
  { country: "United States", province: "Iowa" },
  { country: "United States", province: "Kansas" },
  { country: "United States", province: "Kentucky" },
  { country: "United States", province: "Louisiana" },
  { country: "United States", province: "Maine" },
  { country: "United States", province: "Marshall Islands" },
  { country: "United States", province: "Maryland" },
  { country: "United States", province: "Massachusetts" },
  { country: "United States", province: "Michigan" },
  { country: "United States", province: "Minnesota" },
  { country: "United States", province: "Mississippi" },
  { country: "United States", province: "Missouri" },
  { country: "United States", province: "Montana" },
  { country: "United States", province: "Nebraska" },
  { country: "United States", province: "Nevada" },
  { country: "United States", province: "New Hampshire" },
  { country: "United States", province: "New Jersey" },
  { country: "United States", province: "New Mexico" },
  { country: "United States", province: "New York" },
  { country: "United States", province: "North Carolina" },
  { country: "United States", province: "North Dakota" },
  { country: "United States", province: "Northern Mariana Islands" },
  { country: "United States", province: "Ohio" },
  { country: "United States", province: "Oklahoma" },
  { country: "United States", province: "Oregon" },
  { country: "United States", province: "Palau" },
  { country: "United States", province: "Pennsylvania" },
  { country: "United States", province: "Puerto Rico" },
  { country: "United States", province: "Rhode Island" },
  { country: "United States", province: "South Carolina" },
  { country: "United States", province: "South Dakota" },
  { country: "United States", province: "Tennessee" },
  { country: "United States", province: "Texas" },
  { country: "United States", province: "Utah" },
  { country: "United States", province: "Vermont" },
  { country: "United States", province: "Virginia" },
  { country: "United States", province: "Washington" },
  { country: "United States", province: "West Virginia" },
  { country: "United States", province: "Wisconsin" },
  { country: "United States", province: "Wyoming" },
  { country: "United States", province: "Virgin Islands" },
  { country: "United States", province: "Armed Forces Americas" },
  { country: "United States", province: "Armed Forces Europe" },
  { country: "United States", province: "Armed Forces Pacific" },
];

import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { NewItem } from "@redotech/redo-customer-portal-shared/hooks/useReturnConfirmation/use-return-confirmation";
import { CURRENCY_FORMAT } from "@redotech/redo-model/money";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { ItemDetail } from "../../shared-components/item-detail";

export function NewItemsCard({
  itemsHeader,
  newItems,
}: {
  itemsHeader: string;
  newItems: NewItem[] | undefined;
}) {
  const screen = useScreenType();
  return (
    <Flex flexDirection="column" gap="xl">
      <Text fontSize="lg" fontWeight="semibold">
        {itemsHeader}
      </Text>
      {newItems?.map((item, i) => (
        <ItemDetail
          formattedPrice={CURRENCY_FORMAT(item.currency).format(+item.price)}
          id={item.variantTitle + i}
          imageSrc={item.image}
          isSelected={false}
          isVertical={screen === ScreenType.DESKTOP}
          key={item.variantTitle + i}
          quantity={1}
          subtitle={
            item.variantTitle != "Default Title" ? item.variantTitle : undefined
          }
          title={item.productTitle}
          variants={[]}
        />
      ))}
    </Flex>
  );
}
